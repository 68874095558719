import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from './i18n/index' //国际化
/* 重置样式 */
// import './assets/js/jquery.js'

import "./assets/css/reset.min.css";
import "./assets/css/bootstrap.min.css";
import './assets/js/bootstrap.min.js'
import "wowjs/css/libs/animate.css";
/* animate.css */
// import 'animate.css'
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
