export const lang = {
	"公司地址": "9th Floor, T1 Building, Poly International Plaza, Chaoyang District, Beijing, China.",
	"公司介绍": "Company introduction",
	"品类介绍": "Category introduction",
	"服务能力": "Service capacity",
	"需求反馈": "Feedback on requirements",
	"合作伙伴": "Partners",
	"联系我们": "Contact us",
	"全球优质供应链服务商": "Global high-quality supply chain service provider",
	"为全球品牌和企业提供定制化产品解决方案": "Provide customized product solutions for global brands and enterprises",
	"华汾公司介绍": "Introduction to Huafen (Beijing) International Trade Co., Ltd.",

	"company-profile-title1":"Huafen (Beijing) International Trade Co., Ltd. ",
	"company-profile1":"is a comprehensive international trade enterprise that merges industrial product R&D, production, design, global brand marketing, and import-export sales into a cohesive operation. Our unique approach leverages Web 3.0 decentralized technology to provide top-tier internet marketing and digital management services. We excel in orchestrating intelligent digital strategies across a global trade network, delivering exceptional value to brands and enterprises.",

	"company-profile-title2":"Strategic Advantage: ",
	"company-profile2":"We capitalize on national shipping advantages and a robust advertising network spanning 89 cities and 90 airports, effectively covering over 90% of urban landmarks. This extensive reach allows us to connect with China’s vast population of 1.4 billion, significantly enhancing brand visibility and impact. ",

	"company-profile-title3":"Innovative Product Offerings: ",
	"company-profile3":"At Huafen, we pride ourselves on driving technological advancement and innovation. We introduce high-quality lifestyle products with international cultural significance to global consumers, showcasing China’s trade development and innovative capabilities on the world stage. ",

	"company-profile-title4":"Our Product Series:",
	"company-profile4":"Huafen manages three distinct product series: ",


	"company-profile-title5":"1. Proprietary Brands ",
	"company-profile5":" - Brands developed and owned by Huafen, reflecting our commitment to innovation and quality. ",

	"company-profile-title6":"2. Internationally Authorized Brands",
	"company-profile6":" - Globally recognized brands for which we hold distribution rights, ensuring quality and authenticity. ",

	"company-profile-title7":"3. Agency Brands",
	"company-profile7":" - Brands we represent and market, leveraging our expertise to expand their reach and impact. ",

	"company-profile-title8":"Comprehensive Industrial Chain: ",
	"company-profile8":"Our company boasts a complete industrial chain that integrates product R&D, production, sales, logistics, and marketing. This holistic approach ensures seamless operations and exceptional service, aligning with our goal of delivering superior products and solutions to our clients and partners worldwide.",


	"我们能提供的品类": "Categories We Can Offer",
	"个护清洁": "Personal Care and Cleaning",
	"清洁工具": "Cleaning Tools",
	"家庭清洁": "Household Cleaning",
	"衣物清洁": "Laundry Cleaning",
	"洗发护发": "Hair Care",
	"身体护理": "Body Care",
	"纸品湿巾": "Paper Products and Wet Wipes",
	"口腔护理": "Oral Care",
	"驱蚊驱虫": "Mosquito and Pest Control",
	"食品酒饮": "Food and Beverages",
	"乳饮冰品": "Dairy and Frozen Products",
	"中外名酒": "Domestic and Foreign Liquor",
	"方便速食": "Instant Food",
	"粮油调味": "Grains, Oils, and Seasonings",
	"咖啡": "Coffee",
	"休闲食品": "Snacks",
	"美妆护肤": "Beauty and Skincare",
	"面部护肤": "Facial Skincare",
	"香水": "Perfume",
	"彩妆": "Makeup",
	"家电": "Home Appliances",
	"家电配件": "Appliance Accessories",
	"厨房小电": "Kitchen Small Appliances",
	"清洁电器": "Cleaning Appliances",
	"个护健康": "Personal Care and Health",
	"空调": "Air Conditioning",
	"冰箱": "Refrigerator",
	"电视": "Television",
	"厨卫大电": "Large Kitchen and Bathroom Appliances",
	"视听影音": "Audio and Video",
	"家居厨具": "Home and Kitchenware",
	"火机烟具": "Lighters and Smoking Accessories",
	"餐具": "Tableware",
	"烹饪厨具": "Cooking Utensils",
	"水具酒具": "Drinkware and Barware",
	"生活日用": "Daily Necessities",
	"居家布艺": "Home Textiles",
	"床上用品": "Bedding",
	"汽摩生活": "Automotive and Motorcycle Life",
	"电动车": "Electric Vehicles",
	"摩托车": "Motorcycles",
	"农用机械": "Agricultural Machinery",
	"搬运设备": "Handling Equipment",
	"装饰用品": "Decorative Items",
	"派对用品": "Party Supplies",
	"母婴": "Maternal and Infant",
	"奶粉": "Baby Formula",
	"尿布湿巾": "Diapers and Wipes",
	"洗护用品": "Personal Care Products",
	"营养辅食": "Nutritional Supplements",
	"喂养工具": "Feeding Tools",
	"欢迎访问 Go2Pie商城，查找商品": "Welcome to Go2Pie Mall, where you can find products",
	"我们的服务能力": "Our Service Capabilities",
	"贸易": "Trade",
	"自主工厂生产及国内外品牌直供": "Self-owned factory production and direct supply from domestic and international brands",
	"自研国际自由贸易平台Goπ": "Self-developed international free trade platform Goπ",
	"品牌授权及合作线下门店": "Brand authorization and cooperation with offline stores",
	"国内外各大品牌经销/代理商": "Distributors/agents of major domestic and international brands",
	"国际线上交易支付管理系统": "International online transaction payment management system",
	"营销": "Marketing",
	"整合营销推广解决方案": "Integrated marketing promotion solutions",
	"媒体营销推广": "Media marketing promotion",
	"社交平台运营": "Social media platform operations",
	"电商平台运营": "E-commerce platform operations",
	"内容创意及制作": "Content creation and production",
	"物流配送": "Logistics and distribution",
	"顶级物流体系及航运支持": "Top-tier logistics system and shipping support",
	"全国物流和冷链配送": "Nationwide logistics and cold chain distribution",
	"国际供应及国际运输": "International supply and transportation",
	"提供销售通路搭建、企业集采及一件代发等全方位服务": "Offering full-service services including sales channel development, bulk procurement, and dropshipping",
	"需求反馈收集": "Collection of demand feedback",
	"我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系": "We can meet all your needs. Please leave your requirements, and we will contact you promptly",
	"提交需求反馈表单": "Submit the demand feedback form",
	"集团合作伙伴": "Group Partners",
	"集团": "Group",
	"合作伙伴及品牌": "Partners and Brands",
	"合作媒体": "Partner Media",
	"华汾版权所有": "All Rights Reserved By Huafen",

	"收起": "Put it away",
	"展开": "Open",


	"食品":"food",


	"咖啡":"coffee",
	"胶囊咖啡": "Capsule coffee",
	"黑咖啡": "Black Coffee",
	"白咖啡": "White Coffee",
	"咖啡豆/粉": "Coffee beans/powder",
	"滴咖啡": "Drip Coffee",
	"咖啡液": "Coffee liquid",
	"咖啡伴侣": "Coffee mate",
	"速溶咖啡": "instant coffee",
	"即饮咖啡": "Ready-to-drink coffee",
	"速溶黑咖啡": "Instant black coffee",
	"冻干咖啡": "Freeze-dried coffee",
	"三倍的": "triple",

	"可饮用谷物":"Drinkable Cereals",
	"大米": "rice",
	"煎堆": "Sesame balls",
	"奶茶": "milk tea",
	"豆浆": "Soy milk",
	"莲藕淀粉": "lotus root starch",
	"全麦谷物": "Whole wheat cereal",
	"谷物粉": "Grain Flour",
	"水果麦片": "Fruit cereal",
	"代餐摇床": "Meal Replacement Shakes",
	"成人奶粉": "Adult milk powder",
	"霍尼": "Honey",

	"牛奶饮料和冰淇淋":"Milk drinks and ice cream",
	"闪闪发光的水": "Sparkling water",
	"碳酸饮料": "Carbonated drinks",
	"能量饮料": "Energy Drinks",
	"乳酸菌饮料": "Lactic acid bacteria beverage",
	"果蔬汁": "Fruit and vegetable juice",
	"茶饮料": "Tea Beverages",
	"酸奶": "yogurt",
	"纯牛奶": "Pure milk",
	"饮用水": "drinking water",
	"冰淇淋": "ice cream",

	"中外著名葡萄酒":"Famous Chinese and Foreign Wines",
	"洋酒": "Foreign wine",
	"白酒类": "Liquor",
	"啤酒": "beer",
	"葡萄酒": "wine",
	"黄酒/保健酒": "Yellow wine/health wine",

	"谷物和油调味品":"Grain and oil seasoning",
	"中国调味品": "Chinese seasoning",
	"西餐": "Western style meal",
	"配菜": "Side dishes",
	"东南亚调味品": "Southeast Asian Seasoning",
	"花生菜籽油": "Peanut rapeseed oil",
	"葵花籽油": "Sunflower Oil",
	"米油": "Rice oil",
	"橄榄油": "olive oil",
	"茶油": "tea oil",
	"谷物": "Cereals",
	"粉丝": "vermicelli",
	"面粉": "flour",

	"南北干货":"North and South Dry Goods",
	"银耳": "Tremella",
	"扇贝": "Scallops",
	"干蔬菜": "Dried vegetables",
	"真菌": "fungus",
	"龙眼": "Longan",
	"莲子": "Lotus seeds",
	"干蘑菇": "Dried Mushrooms",
	"干海产品": "Dried seafood",
	"有机干货": "Organic Dried Food",

	"方便快餐":"Convenient fast food",
	"午餐肉": "Luncheon Meat",
	"自热食品": "Self-heating food",
	"米粉": "Rice noodles",
	"速溶汤": "Instant soup",
	"火腿": "Ham",
	"蜗牛米粉": "Snail Rice Noodles",
	"方便面": "instant noodles",
	"粽子": "Zongzi",
	"青团": "Qingtuan",

	"零食":"Snack Food",
	"低热量零食": "Low-calorie snacks",
	"饼干和糕点": "Biscuits and pastries",
	"愚蠢的": "Jerky",
	"坚果和烤坚果": "Nuts and Roasted Nuts",
	"蜜饯水果": "Candied Fruit",
	"糖果": "candy",
	"零食礼品包": "Snacks gift pack",
	"麻辣豆腐干": "Spicy Dried Tofu",
	"红烧鸡爪": "Braised Chicken Feet",
	"薯片膨化": "Potato Chips Puffing ",
	"果冻布丁": "Jelly Pudding ",
	"巧克力": "chocolate ",

	"茶":"Tea",
	"菊花茶": "Chrysanthemum tea ",
	"绿茶": "green tea ",
	"红茶": "black tea ",
	"普洱茶": "Pu'er Tea ",
	"乌龙茶": "Oolong Tea ",
	"茉莉花茶": "Jasmine tea ",
	"健康茶": "Health tea ",
	"白茶": "White Tea ",
	"西湖龙井": "West Lake Longjing ",
	"铁观音": "Tieguanyin ",
	"红茶": "Black Tea",


	"生鲜":"Fresh",

	
	"快速烘焙":"Quick Baking",
	"方便面": "Instant Noodles",
	"粤式早茶": "Cantonese style morning tea",
	"烘焙半成品": "Baking semi-finished products",
	"面条": "Noodles",
	"饺子蒸饺和炸饺子": "Dumplings Steamed and Fried Dumplings",
	"馄饨": "Wonton",
	"奶酪黄油": "Cheese Butter",
	"蛋糕和甜点": "Cakes and Desserts",
	"汤圆元宵": "Tangyuan Yuanxiao",
	"披萨": "Pizza",
	"蛋挞": "Egg Tart",
	"低温粽子": "Low temperature rice dumplings",

	"预先准备的菜肴":"Pre-prepared dishes",
	"油炸食品": "Fried Food",
	"便餐": "Light Meals",
	"熟食": "Cooked Food",
	"火锅配料": "Hot pot ingredients",
	"培根香肠": "Bacon Sausage",
	"半成品菜肴": "Semi-finished dishes",
	"海鲜配菜": "Seafood prepared dishes",
	"当地特产": "Local specialties",
	"一个人": "One person",

	"肉类和家禽产品":"Meat and Poultry Products",
	"冷肉": "Cold Meat",
	"牛排": "steak",
	"牛肉": "beef",
	"鸡": "chicken",
	"鸡蛋": "egg",
	"猪肉": "pork",
	"羊肉": "mutton",
	"鸭子": "duck",

	"蔬菜":"vegetable",
	"大豆制品": "Soy products",
	"根状茎": "Rhizomes",
	"红薯": "sweet potato",
	"有机时令蔬菜": "Organic seasonal vegetables",
	"玉米": "corn",
	"茄瓜": "Solanum and melon",
	"绿叶蔬菜": "Leafy vegetables",
	"洋葱、姜、大蒜和胡椒": "Onion, ginger, garlic and pepper",
	"冷冻蔬菜": "Frozen vegetables",
	"新鲜蘑菇": "Fresh mushrooms",

	"海鲜": "Seafood",
	"新鲜": "Fresh",
	"鲑鱼": "salmon",
	"鱼": "Fish",
	"小龙虾": "crayfish",
	"小虾": "Shrimp",
	"贝类": "shellfish",
	"大闸蟹": "Hairy Crab",
	"蟹": "Crabs",
	"海参": "Sea Cucumber",

	"新鲜水果":"Fresh Fruit",
	"进口水果": "Imported Fruits",
	"桃、李、杏": "Peach, Plum, Apricot",
	"苹果": "apple",
	"榴莲": "durian",
	"芒果": "mango",
	"梨": "pear",
	"樱桃": "Cherry",
	"猕猴桃": "Kiwi fruit",
	"荔枝": "litchi",
	"蓝莓": "blueberry",
	"橙色": "orange",
	"橙色/橘黄色": "Orange/Tangerine",
	"椰青": "Young Coconut",
	"水果礼盒/礼券": "Fruit gift box/voucher",


	"个护清洁":"Personal care cleaning",


	"纸品湿巾":"Paper wipes",
	"抽纸": "Paper ",
	"手帕纸": "Handkerchief ",
	"卷纸": "Roll paper ",
	"湿巾": "Wet wipes ",
	"厨房纸巾": "Paper towel ",

	"洗发护发":"Shampoo and Conditioner",
	"洗发水": "Shampoo ",
	"护发素": "Conditioner ",
	"发膜": "Hair Mask ",
	"生发液": "Hair growth liquid ",
	"护发精油": "Hair Care Oil ",
	"洗护套装": "Cleansing Set ",

	"身体护理":"Body Care",
	"精油": "Essential Oils ",
	"沐浴露": "body wash ",
	"男士沐浴": "Men's Bath ",
	"儿童沐浴": "Children's bath ",
	"香皂": "Soap ",
	"洗手液": "handwashing fluid ",
	"美体/工具": "Body/Tools ",
	"护手霜": "Hand Cream ",
	"浴盐": "Bath Salts ",
	"身体护理套": "Body Care Set ",
	"花露水": "Floral water ",

	"口腔护理":"Oral Care",
	"牙膏": "toothpaste ",
	"儿童口腔": "Children's Oral Care ",
	"牙刷": "toothbrush ",
	"漱口水": "Mouthwash ",
	"牙线/牙线棒": "Dental floss/floss sticks ",
	"口喷": "Mouth spray ",
	"牙贴": "Tooth stickers ",

	"衣物清洁":"Clothes cleaning",
	"洗衣液": "Laundry Detergent ",
	"洗衣粉": "washing powder ",
	"凝珠": "Gel beads ",
	"洗衣皂": "Laundry Soap ",
	"柔顺剂": "Fabric Softener ",
	"除菌液": "Sterilization liquid ",

	"女性护理":"Feminine Care",
	"卫生巾": "sanitary napkin ",
	"护垫": "Pads ",
	"棉条": "Tampon ",
	"裤型卫生巾": "Pants sanitary napkins ",
	"私处护理": "Private care ",

	"驱蚊驱虫":"Mosquito and insect repellent",
	"灭蚁": "Ant Killer ",
	"杀蟑": "Kill cockroaches ",
	"驱鼠": "Repelling rats ",
	"除满": "Full ",
	"驱蝇": "Fly repellent ",

	"清洁工具":"Cleaning tools",
	"拖把": "mop ",
	"垃圾桶": "garbage can ",
	"垃圾袋": "trash bag ",
	"清洁刷具": "Cleaning brush ",
	"抹布/百洁布": "Rags/Scrubbers ",
	"手套/鞋套": "Gloves/shoe covers ",
	"一次性清洁用品": "Disposable cleaning supplies ",
	"扫把": "broom ",
	"脸盆/水桶": "Washbasin/bucket ",

	"发型设计":"Hairstyling",
	"发胶": "Hairspray ",
	"发蜡/泥": "Hair wax/mud ",
	"定型啫喱": "Styling gel ",
	"染发": "Hair Coloring ",
	"假发": "wig ",
	"弹力素": "Elastin ",
	"美发工具": "Hairdressing Tools ",
	"蓬蓬粉": "Fluffy Pink ",

	"家庭清洁":"Household Cleaning",
	"油污净": "Oil Cleaner ",
	"洗洁精": "Dishwashing liquid ",
	"洁厕剂": "Toilet Cleaner ",
	"消毒液": "Disinfectant ",
	"地板清洁剂": "Floor Cleaner ",
	"除湿干燥剂": "Dehumidification desiccant ",

	"成人护理":"Adult Care",
	"成人纸尿裤": "Adult Diapers ",
	"成人拉拉裤": "Adult pull-up pants ",
	"成人护理垫": "Adult nursing pads ",
	"成人纸尿垫": "Adult diaper pad",


	"美妆护肤":"Beauty and Skin Care",


	"面部护肤":"Facial Skin Care",
	"防晒": "Sun protection",
	"眼霜眼部精华": "Eye cream eye essence",
	"护肤": "Skin Care",
	"洁面": "Cleansing",
	"化妆水": "Toner",
	"卸妆": "Makeup removal",
	"润唇膏": "Lip Balm",
	"眼膜": "Eye Mask",
	"T区护理": "T-Zone Care",
	"唇膜唇部精华": "Lip mask lip essence",

	"彩妆":"Makeup",
	"隔离霜/妆前乳": "Makeup base/primer",
	"粉底液/膏": "Liquid foundation/cream",
	"唇彩唇蜜/唇釉": "Lip gloss/lip glaze",
	"气垫": "air cushion",
	"BB霜": "BB Cream",
	"蜜粉/散粉": "Loose powder",
	"眉笔/眉粉": "Eyebrow pencil/powder",
	"粉饼": "Pressed Powder",
	"美甲": "Nail Art",
	"眼影": "Eyeshadow",
	"眼线笔/眼线液": "Eyeliner/Liquid Eyeliner",
	"遮瑕": "Concealer",
	"腮红/胭脂": "Blush/Rouge",
	"睫毛膏/睫毛液": "Mascara/Mascara",
	"CC霜": "CC Cream",
	"修容/高光/阴影": "Contouring/Highlights/Shadows",
	"唇笔/唇线笔": "Lip pencil/lip liner",
	"女士香水": "Women's Perfume",
	"男士香水": "Men's Fragrance",

	"美妆工具":"Beauty tools",
	"美甲工具": "Nail tools",
	"化妆棉": "Cosmetic cotton pads",
	"化妆刷": "Makeup brushes",
	"粉扑/洗脸扑": "Powder puff/face cleanser",
	"假睫毛": "False eyelashes",
	"修眉刀": "Eyebrow trimmer",
	"双眼皮贴": "Double eyelid tape",
	"睫毛夹": "Eyelash Curler",
	"美妆工具": "Beauty tools",
	"吸油纸": "Oil absorbing paper",


	"家居厨具":"Home Kitchenware",


	"生活日用":"Daily life",
	"香薰/蜡烛": "Aromatherapy/Candle",
	"衣架": "Coat hanger",
	"指甲刀": "Nail Clippers",
	"纸巾盒": "Tissue Box",
	"雨伞雨具": "Umbrellas and rain gear",
	"日杂小件": "Daily sundries",
	"梳子": "comb",
	"浴室置物架": "Bathroom Shelves",
	"移动马桶": "Portable toilet",
	"保暖防护": "Warm protection",
	"洗漱杯": "Washing cup",
	"搓澡巾": "Bath towel",
	"马桶刷": "Toilet brush",
	"净化除味": "Purification and deodorization",
	"化妆镜": "Makeup mirror",

	"水具酒具":"Water and wine utensils",
	"保温杯": "Thermos Cup",
	"玻璃杯": "glass",
	"塑料杯": "Plastic cup",
	"陶瓷/马克杯": "Ceramics/Mug",
	"保温壶": "Thermos Flask",
	"运动水壶": "Sports bottle",
	"焖烧杯": "Stew cup",
	"酒杯/酒具": "Wine glasses/wineware",

	"烹饪锅具":"Cooking Pots",
	"炒锅": "frying pan",
	"平底锅/煎锅": "Frying pan",
	"汤锅": "Stockpot",
	"蒸锅": "Steamer",
	"奶锅": "Milk Pan",
	"高压锅": "Pressure cooker",
	"火锅": "hot pot",

	"餐具":"tableware",
	"碗": "bowl",
	"盘/碟": "Plate/dish",
	"筷子": "Chopsticks",
	"刀/叉/勺": "Knife/fork/spoon",
	"果盘/果篮": "Fruit plate/basket",
	"一次性用品": "Disposables",

	"厨房配件":"Kitchen accessories",
	"上水器": "Water dispenser",
	"真空机": "Vacuum machine",
	"厨房储物器皿": "Kitchen Storage",
	"壁挂置物架": "Wall Mounted Rack",
	"落地置物架": "Floor rack",
	"台面置物架": "Countertop rack",
	"保鲜盒": "Fresh-keeping box",
	"饭盒/提锅": "Lunch box/pot",
	"调料器皿": "Seasoning utensils",
	"厨房秤": "Kitchen scale",
	"烘培用具": "Baking utensils",
	"厨房DIY/小工具": "Kitchen DIY/Gadgets",

	"火机烟具":"Lighters and smoking accessories",
	"煤油打火机": "Kerosene lighter",
	"一次性打火机": "Disposable lighter",
	"火机油": "Lighter fluid",
	"充电打火机": "Rechargeable lighter",
	"烟嘴": "Cigarette holder",
	"烟斗": "pipe",
	"雪茄用品": "Cigar Supplies",
	"戒烟贴": "Quit Smoking Patches",
	"烟灰缸": "ashtray",

	"茶具/咖啡具":"Tea/coffee set",
	"茶杯": "cup",
	"茶壶": "teapot",
	"茶具配件": "Tea accessories",
	"茶盘": "tea tray",
	"咖啡杯": "Coffee cup",
	"旅行茶具": "Travel Tea Set",
	"咖啡具配件": "Coffee accessories",

	"刀剪菜板":"Knife and cutting board",
	"砧板": "Cutting Board",
	"菜刀": "kitchen knife",
	"瓜果刀/刨": "Fruit knife/planer",
	"剪刀": "Scissors",
	"多功能到": "Multifunctional to",

	"地毯底垫":"Carpet underlay",
	"浴室地垫": "Bathroom mats",
	"入户地垫": "Entrance mat",
	"商用地毯": "Commercial Carpet",
	"床边毯": "Bedside Blanket",
	"厨房地垫": "Kitchen floor mats",
	"客厅地毯": "Living room carpet",

	"收纳用品":"Storage supplies",
	"收纳洞洞板": "Storage pegboard",
	"首饰盒": "Jewelry Box",
	"夹缝收纳柜": "Crack storage cabinet",
	"化妆品收纳盒": "Cosmetic Storage Box",
	"搬家纸箱": "Moving boxes",
	"收纳箱": "Storage Box",
	"鞋盒": "Shoebox",
	"搬家袋": "Moving bags",
	"桌面收纳盒": "Desktop Storage Box",
	"内衣收纳盒": "Underwear Storage Box",
	"真空收纳袋": "Vacuum Storage Bags",
	"旅行收纳袋": "Travel Storage Bag",
	"化妆品收纳包": "Cosmetic Storage Bag",
	"手办收纳盒": "Figure Storage Box",
	"收纳柜": "Storage cabinet",

	"居家布艺":"Home Fabric",
	"毛巾浴巾": "Towels and bath towels",
	"沙发垫": "Sofa cushions",
	"门帘": "Door Curtain",
	"一次性洗脸巾": "Disposable face towel",
	"飘窗垫": "Bay window mat",
	"干发帽": "Hair Dryer Cap",
	"窗纱": "window screening",
	"蒲团": "Futon",
	"毛毯": "Blanket",
	"窗帘": "curtain",
	"抱枕靠垫": "Pillows and cushions",
	"桌布": "tablecloth",

	"床上用品":"Bedding",
	"枕头": "Pillow",
	"床垫床褥": "Mattress",
	"床单床笠": "Bed Sheets",
	"蚊帐": "mosquito net",
	"棉花被": "Cotton Quilt",
	"四件套": "Four-piece set",
	"纤维被": "Fiber Quilt",
	"蚕丝被": "Silk quilt",
	"羽绒服被": "Down Quilt",
	"电热毯": "Electric blanket",
	"凉席": "Cool mat",
	"羊毛被": "Wool Quilt",
	"水暖毯": "Plumbing Blanket",
	"凉感被": "Cool quilt",


	"家电":"Home Appliances",


	"环境电器":"Environmental appliances",
	"电风扇": "Electric fan",
	"净水器": "Water Purifier",
	"空气净化器": "Air Purifier",
	"茶吧机": "Tea bar machine",
	"除湿机": "Dehumidifier",
	"取暖器": "Heater",
	"电暖桌": "Electric heating table",
	"电油灯": "Oil lamp",
	"软水机": "Water Softener",
	"即热饮水机": "Hot water dispenser",
	"加热净水器": "Heating water purifier",
	"全屋净水": "Whole house water purification",
	"加湿净化器": "Humidifier Purifier",
	"无雾加湿器": "Mist-free humidifier",
	"移动地暖": "Mobile floor heating",

	"空调":"air conditioner",
	"变频空调": "Inverter air conditioner",
	"新风空调": "Fresh air conditioning",
	"新一级能效": "New level of energy efficiency",
	"空调柜机": "Air conditioning cabinet",
	"空调挂机": "Air conditioner",
	"中央空调": "Central air conditioning",

	"冰箱":"refrigerator",
	"多门冰箱": "Multi-door refrigerator",
	"对开门冰箱": "Double door refrigerator",
	"十字对开门": "Cross door",
	"双门冰箱": "Double door refrigerator",
	"单门冰箱": "Single door refrigerator",
	"三门冰箱": "Three door refrigerator",
	"冷柜/冰吧": "Freezer/Ice Bar",
	"红酒柜": "Wine Cabinet",

	"洗衣机":"washing machine",
	"洗烘一体机": "Washer-dryer",
	"滚筒洗衣机": "Drum washing machine",
	"迷你洗衣机": "Mini washing machine",
	"烘干机": "Dryer",

	"电视":"television",
	"K歌电视": "Karaoke TV",
	"艺术电视": "Art TV",
	"会议电视": "Conference TV",
	"游戏电视": "Gaming TV",
	"85吋电视": "85-inch TV",
	"98吋电视": "98-inch TV",
	"75吋电视": "75-inch TV",
	"miniled电视": "mini led tv",
	"电视挂架": "TV mount",
	"电视推车": "TV cart",
	"电视遥控器": "TV remote control",

	"厨房小电":"Kitchen appliances",
	"电饭煲": "Rice Cooker",
	"电水壶热水瓶": "Electric kettle thermos",
	"电磁炉": "induction cooker",
	"破壁机": "Wall breaking machine",
	"电压力锅": "Electric pressure cooker",
	"养生壶": "Health pot",
	"电饼档": "Electric pancake stall",
	"微波炉": "Micro-wave oven",
	"空气炸锅": "Air fryer",
	"咖啡机": "Coffee machine",
	"电烤箱": "Electric oven",
	"三明治早餐机": "Sandwich Breakfast Maker",
	"果蔬净化器": "Fruit and vegetable purifier",
	"炒菜机器人": "Cooking robot",
	"电动榨汁杯": "Electric juicer",
	"电煮锅": "Electric Cooker",
	"刀筷消毒机": "Knife and chopstick disinfection machine",
	"电热火锅": "Electric hot pot",
	"电蒸锅": "Electric Steamer",
	"厨师机": "Kitchen Machine",
	"绞肉机": "Meat grinder",

	"厨卫大电":"Kitchen and bathroom appliances",
	"油烟机": "Range hood",
	"灶具": "Cooker",
	"电热水具": "Electric water heater",
	"燃气热水器": "Gas water heater",
	"消毒柜": "Disinfection cabinet",
	"洗碗机": "dishwasher",
	"壁挂炉": "Wall-mounted boiler",
	"空气能热水器": "Air Energy Water Heater",
	"太阳能热水器": "Solar water heater",
	"嵌入式厨电": "Built-in kitchen appliances",
	"电热水龙头": "Electric water faucet",
	"集成水槽": "Integrated sink",
	"集成灶": "Integrated stove",

	"个护健康":"Personal care and health",
	"干衣机": "Clothes dryer",
	"剃须刀": "shaver",
	"按摩器": "Massager",
	"电吹风": "hair dryer",
	"电动牙叔": "Electric Toothbrush",
	"理发器": "Hair Clipper",
	"美容器": "Beauty device",
	"卷/直发器": "Curling/straightening iron",
	"冲牙器": "Water flosser",
	"按摩椅": "Massage Chair",
	"足浴盆": "Foot bath",
	"剃/脱毛器": "Shaving/Epilator",
	"潮流护理电器": "Trendy Care Appliances",
	"洗手机": "Wash your phone",
	"手持挂烫机": "Handheld Garment Steamer",

	"家电配件":"Home appliance accessories",
	"燃气灶支架": "Gas stove bracket",
	"个护健康配件": "Personal care and health accessories",
	"电视支架": "TV bracket",
	"冰箱配件": "Refrigerator accessories",
	"电视艺术支架": "TV Art Stand",
	"吸尘器支架": "Vacuum cleaner holder",
	"热水器镁棒": "Water heater magnesium rod",
	"聚水罩": "Water collecting cover",
	"冰箱门封条": "Refrigerator door seal",
	"生活电器配件": "Home Appliance Accessories",
	"洗衣机配件": "Washing machine accessories",
	"空调挡风板": "Air conditioning windshield",
	"电视配件": "TV Accessories",
	"空调遥控器": "Air Conditioner Remote Control",
	"空调配件": "Air conditioning parts",
	"冰箱底座架": "Refrigerator base rack",
	"洗衣机底座": "Washing machine base",
	"空调支架": "Air conditioner bracket",
	"厨房小电配件": "Kitchen appliances",
	"厨卫大电配件": "Kitchen and bathroom electrical accessories",
	"洗衣机排水管": "Washing machine drain pipe",

	"清洁电器":"Cleaning appliances",
	"扫地机器人": "Robot Vacuum Cleaner",
	"蒸汽电动拖把": "Steam Electric Mop",
	"除螨仪": "Mite removal device",
	"擦窗机器人": "Window cleaning robot",
	"洗地机": "Floor scrubber",

	"视听影音":"Audio-visual",
	"麦克风": "microphone",
	"KTV音响": "KTV audio",
	"家庭影响": "Family influence",
	"功放": "Amplifier",
	"迷你音响": "Mini Speaker",
	"电视音响": "TV speakers",


	"家居家装":"Home Decoration",


	"五金工具":"Hardware tools",
	"家用五金": "Household Hardware",
	"电钻": "Electric drill",
	"螺丝刀": "screwdriver",
	"增压泵": "Booster pump",
	"水平仪": "Level",
	"工具套装": "Tool Kit",
	"电锯": "Electric saw",
	"角磨机": "Angle grinder",
	"测距仪": "rangefinder",
	"五金工具箱": "Hardware tool box",
	"水平尺": "Level",
	"家用电梯": "Home Elevator",
	"购物车": "Cart",
	"机械锁": "Mechanical lock",
	"智能锁": "Smart Lock",

	"电工电料":"Electrical materials",
	"开关插座": "Switches and sockets",
	"智能家居": "Smart Home",
	"电料配件": "Electrical accessories",
	"智能开关": "Smart Switch",
	"电线/电缆": "Wires/Cables",
	"配电箱/断路器": "Distribution box/circuit breaker",
	"电气控制": "Electrical Control",
	"智能窗帘": "Smart curtains",

	"书房家具":"Study furniture",
	"电脑桌": "Computer desk",
	"电脑椅": "Computer Chair",
	"书架": "bookshelf",
	"书桌": "desk",
	"升降桌": "Lift Table",
	"红木书桌": "Mahogany desk",

	"餐厅家具":"Dining Room Furniture",
	"岩板餐桌": "Rock slab dining table",
	"实木餐桌": "Solid wood dining table",
	"岛台餐桌": "Island dining table",
	"折叠餐桌": "Folding dining table",
	"餐车": "dining car",
	"餐边柜": "Sideboard",
	"餐椅": "Dining Chair",
	"红木餐桌": "Mahogany dining table",
	"红木餐边桌": "Rosewood side table",

	"阳台储物":"Balcony storage",
	"飘窗桌": "Bay window table",
	"层架/置物架": "Shelves/Racks",
	"智能晾衣架": "Smart clothes drying rack",
	"阳台柜": "Balcony Cabinet",
	"衣帽架": "Coat rack",
	"落地晾衣架": "Floor drying rack",
	"隐藏晾衣架": "Hidden clothes drying rack",
	"鞋架": "Shoe rack",
	"储物/收纳": "Storage",
	"手摇晾衣架": "Hand-cranked clothes drying rack",
	"墙面搁架": "Wall Shelves",
	"泳池躺椅": "Pool loungers",

	"厨房卫浴":"Kitchen and Bathroom",
	"厨房配件": "Kitchen accessories",
	"淋浴花洒": "Shower",
	"龙头": "Faucet",
	"厨卫挂件": "Kitchen and bathroom accessories",
	"水槽": "sink",
	"马桶": "toilet",
	"陶瓷件组套": "Ceramic parts set",
	"浴室柜": "Bathroom Cabinets",
	"浴霸": "Bathroom Heater",
	"净水软水": "Purified water",
	"淋浴房": "Shower Room",
	"智能马桶盖": "Smart toilet seat",
	"浴缸": "tub",
	"沐浴桶": "Bath tub",
	"垃圾处理器": "Garbage disposer",

	"客厅家具":"Living Room Furniture",
	"边桌/茶几": "Side table/coffee table",
	"布艺沙发": "Fabric Sofa",
	"皮艺沙发": "Leather Sofa",
	"鞋柜": "Shoe Cabinet",
	"沙发床": "Sofa Bed",
	"电视柜": "TV Cabinet",
	"穿衣镜": "Dressing mirror",
	"实木沙发": "Solid wood sofa",
	"屏风": "screen",
	"懒人沙发": "Bean Bag",
	"功能沙发": "Functional sofa",
	"酒柜": "Wine Cabinet",
	"红木沙发": "Mahogany sofa",
	"壁炉": "fireplace",
	"红木电视柜": "Rosewood TV cabinet",

	"庭院户外":"Courtyard Outdoor",
	"折叠穿": "Folding wear",
	"遮阳伞": "Parasols",
	"充气家具": "Inflatable furniture",
	"遮阳棚": "Awning",
	"家用梯": "Household ladder",
	"户外桌椅": "Outdoor tables and chairs",
	"木屋凉亭": "Log Cabin Pavilion",
	"秋千": "Swing",
	"岗亭岗台": "Guard booth",
	"户外沙发": "Outdoor sofa",
	"吊篮": "Hanging Basket",

	"卧室家具":"Bedroom furniture",
	"实木床": "Solid wood bed",
	"皮艺床": "Leather bed",
	"衣柜": "Wardrobe",
	"实木衣柜": "Solid wood wardrobe",
	"床头柜": "Bedside Table",
	"床垫": "Mattress",
	"乳胶床垫": "Latex mattress",
	"弹簧床垫": "Spring mattress",
	"简易衣柜": "Simple Wardrobe",
	"斗柜": "Chest of drawers",
	"布艺床": "Fabric bed",
	"床尾凳": "Bed footstool",
	"红木床": "Mahogany bed",
	"红木衣柜": "Mahogany wardrobe",
	"梳妆台/凳": "Dressing table/stool",

	"儿童家具":"Children's furniture",
	"高低床": "Bunk Bed",
	"绘本架": "Picture book stand",
	"儿童床": "Children's bed",
	"边床": "Side bed",
	"儿童桌椅套装": "Children's table and chair set",
	"儿童椅": "Children's chair",
	"儿童床垫": "Children's mattress",
	"儿童桌": "Children's table",
	"儿童书桌": "Children's desk",
	"儿童衣柜": "Children's Wardrobe",
	"儿童沙发": "Children's sofa",
	"儿童收纳储物": "Children's storage",
	"儿童凳": "Children's stool",
	"儿童玩具桌": "Children's toy table",

	"商业办公":"Commercial Office",
	"适老家具": "Elderly furniture",
	"餐饮家具": "Dining furniture",
	"会议桌": "Conference Table",
	"货架": "Shelves",
	"办公椅": "Office Chair",
	"文件柜": "File Cabinets",

	"灯饰照明":"Lighting",
	"吸顶灯": "Ceiling lamp",
	"台灯": "desk lamp",
	"吊灯": "chandelier",
	"壁灯": "wall lamp",
	"镜前灯": "Mirror light",
	"落地灯": "Floor lamp",
	"氛围照明": "Mood lighting",
	"杀菌灯": "Germicidal lamp",
	

	"母婴":"Mother and Baby",


	"奶粉":"milk powder",
	"特殊配方奶粉": "Special formula milk",
	"有机奶粉": "Organic milk powder",
	"1段": "1 segment",
	"2段": "2nd paragraph",
	"3段": "3 sections",
	"4段": "4 paragraphs",
	"羊奶粉": "Goat milk powder",
	"孕妈奶粉": "Pregnant mother milk powder",

	"尿布湿巾":"Diaper Wipes",
	"拉拉裤": "Pull-on pants",
	"成人尿裤": "Adult Diapers",
	"婴儿湿巾": "Baby wipes",

	"喂养工具":"Feeding tools",
	"奶瓶奶嘴": "Bottles and pacifiers",
	"水壶/水杯": "Kettle/cup",
	"儿童餐具": "Children's tableware",
	"暖奶调奶": "Warming milk",
	"吸奶器": "Breast Pump",
	"牙胶安抚": "Teething soothing",
	"辅食料理机": "Food processor",
	"食物存储": "Food Storage",
	"消毒锅/柜": "Sterilizer/Cabinet",

	"洗护用品":"Toiletries",
	"宝宝护肤": "Baby Skin Care",
	"驱蚊防晒": "Mosquito repellent and sun protection",
	"洗发沐浴": "Shampoo and Bath",
	"婴儿洗鼻器": "Baby nasal rinser",
	"日常护理": "Daily care",
	"口腔清洁": "Oral hygiene",
	"洗澡用具": "Bath supplies",
	"棉柔巾": "Cotton towel",
	"洗衣液/皂": "Laundry detergent/soap",
	"坐便器": "Toilet",
	"奶瓶清洗": "Bottle cleaning",
	"婴儿理发器": "Baby hair clipper",

	"营养辅食":"Nutritional supplementary food",
	"宝宝零食": "Baby snacks",
	"有机辅食": "Organic complementary food",
	"米粉/菜粉": "Rice noodles/vegetable noodles",
	"面条/粥": "Noodles/Porridge",
	"果泥/果汁": "Puree/Juice",
	"清火/开胃": "Clear heat/appetite",
	"DHA": "DHA",
	"益生菌/初乳": "Probiotics/Colostrum",
	"钙铁锌/维生素": "Calcium Iron Zinc/Vitamins",


	"玩具":"Toy",


	"潮流玩具/毛绒":"Fashion Toys/Plush",
	"卡通周边": "Cartoon peripherals",
	"毛绒玩具": "Plush Toys",
	"盲盒": "Blind Box",
	"网游周边": "Online game peripherals",
	"变形模型": "Deformation Model",
	"电影周边": "Movie peripherals",
	"人偶/BJD/兵人": "Dolls/BJD/Soldiers",
	"陀螺": "Gyro",
	"靠垫抱枕": "Cushions",
	"悠悠球/溜溜球": "Yo-Yo/Yo-Yo",
	"扭蛋": "Gachapon",

	"创意减压":"Creative stress relief",
	"减压整蛊": "Stress relief trick",
	"魔术道具": "Magic props",
	"怀旧玩具": "Nostalgic toys",
	"转转笔": "Spinning pen",
	"指尖陀螺": "Fidget Spinner",
	"解锁玩具": "Unlock toys",

	"早教益智":"Early Education Puzzles",
	"早教机/故事机": "Early childhood education machine/storytelling machine",
	"积木": "Building Blocks",
	"过家家玩具": "Pretend Toys",
	"拼图": "puzzle",
	"绘画工具": "Painting tools",
	"爬行垫/毯": "Crawling mat/blanket",
	"魔方": "rubik's cube",
	"桌面玩具": "Desktop Toys",
	"STEAM玩具": "STEAM Toys",
	"仿真动物玩具": "Simulation Animal Toys",
	"摇铃/床铃": "Rattle/Bed Bell",
	"磁力片": "Magnetic sheet",
	"健身架": "Fitness Rack",
	"敲打玩具": "Beating Toys",
	"点读笔": "Point reading pen",

	"遥控/玩具":"Remote Control/Toys",
	"车模": "Car Model",
	"软弹枪": "Soft Bullet Gun",
	"遥控车": "Remote Control Car",
	"遥控飞机": "Remote Control Aircraft",
	"轨道车": "Railcar",
	"飞机模型": "Aircraft Model",
	"遥控船": "Remote Control Boat",
	"机器人": "robot",

	"运动划水":"Sports paddling",
	"洗澡玩具": "Bath Toys",
	"泡泡枪": "Bubble Gun",
	"玩沙玩具": "Sand toys",
	"电动玩具枪": "Electric toy gun",
	"摇马": "Rocking Horse",
	"儿童滑梯": "Children's slide",
	"炫舞毯": "Dance Carpet",

	"特色类目":"Featured Categories",
	"手办": "Figures",


	"文具":"Stationery",


	"笔类书写工具":"Pen writing tools",
	"记号笔": "Marker pen",
	"中性笔": "Gel pen",
	"钢笔": "pen",
	"铅笔": "pencil",
	"笔芯/替芯": "Pen core/refill",
	"墨囊/墨水": "Ink sac/ink",
	"荧光笔": "Highlighter",
	"圆珠笔": "ballpoint pen",
	"白板笔": "Whiteboard Marker",
	"宝珠笔": "Roller pen",
	"针管笔": "Pencil",
	"油漆笔": "Paint Pen",
	"秀丽笔": "Beauty Pen",

	"本册/印刷品":"Booklet/Printed Materials",
	"笔记本记事本": "Notebook",
	"作业本": "Workbook",
	"便利贴": "Post-it Notes",
	"书签": "Bookmarks",
	"标签贴纸": "Labels and stickers",
	"手账": "Notebook",
	"文稿纸草稿纸": "Manuscript paper draft paper",
	"相册": "Photo Album",
	"信封/红包": "Envelope/Red Packet",
	"证书签到册": "Certificate Sign-in Book",
	"活页替芯": "Loose-leaf refills",
	"贺卡/明信片": "Greeting Cards/Postcards",
	"日程本计划本": "Schedule book plan book",
	"日历/台历": "Calendar/desk calendar",
	"日记本": "Diary",
	"信纸": "stationery",
	"冰箱贴防撞贴": "Fridge magnets anti-collision stickers",
	"宣传单/海报": "Leaflets/Posters",
	"同学录纪念册": "Classmates' yearbook",
	"拍纸本": "Paper Notebook",
	"广告纸爆炸贴": "Advertising paper explosion stickers",
	"分页纸索引纸": "Paged paper index paper",
	"奖状": "Certificate of Merit",
	"教学用本": "Teaching Book",
	"立体便签拼图": "3D Sticky Note Puzzle",
	"电话本通讯录": "Phonebook Address Book",

	"学习用品":"School supplies",
	"练字帖": "Calligraphy Practice",
	"文具盒/笔袋": "Pencil Case/Pen Bag",
	"橡皮擦": "Eraser",
	"修正带": "Correction tape",
	"卷笔刀卷比器": "Pencil sharpener",
	"贴纸": "sticker",
	"补习袋": "Tutorial Bag",
	"书皮/书套": "Book Cover/Book Sleeve",
	"坐姿修正器": "Sitting posture corrector",
	"阅读器": "Reader",
	"姓名贴": "Name sticker",
	"握笔器": "Pen Holder",
	"自律打卡器": "Self-discipline time clock",
	"学生计时器": "Student Timer",
	"学生书桌垫": "Student desk pad",
	"儿童卡通印章": "Children's cartoon seal",
	"修正液": "Correction Fluid",
	"笔套/笔帽": "Pen Caps",
	"姓名印章": "Name stamp",
	"桌面吸尘器": "Desktop vacuum cleaner",
	"答题卡/纸": "Answer sheet/paper",
	"挂书袋": "Hanging book bag",
	"红领巾": "red scarf",
	"词卡/图卡": "Word Cards/Picture Cards",
	"放大镜": "magnifier",
	"拼音机口算机": "Pinyin machine mental calculator",
	"学习护指绷带": "Learning finger bandage",
	"少先队徽": "Young Pioneers emblem",
	"压花器": "Embossing machine",
	"修正贴": "Correction sticker",
	"少先队标志": "Young Pioneers logo",

	"财会用品":"Accounting supplies",
	"凭证/单据": "Voucher/Document",
	"印章/印油": "Seal/Ink",
	"账本/账册": "Account book",
	"橡皮筋": "Rubber Band",
	"号码机": "Numbering Machine",
	"印章盒/箱": "Seal box/box",
	"复写纸": "carbon",
	"支票夹": "Check Holder",
	"支票打印机": "Check Printer",
	"湿手器": "Wet Hands",
	"财会软件": "Accounting Software",
	"手提金库": "Portable safe",

	"其他文具用品":"Other stationery supplies",
	"旗帜": "banner",
	"桌牌": "Table Cards",
	"usb风扇": "usb fan",
	"徽章": "badge",
	"奖杯奖牌": "Trophies and medals",
	"温湿度计": "Thermometer and Hygrometer",
	"会议指示牌": "Meeting Sign",
	"许愿瓶": "Wishing Bottle",

	"美术用品":"Art Supplies",
	"美术颜料": "Art paint",
	"马克笔": "Marker pen",
	"画纸/画本": "Drawing paper/painting book",
	"素描铅笔碳棒": "Sketch Pencil Carbon Stick",
	"水彩笔": "Watercolor Pencil",
	"颜料画笔": "Paint brush",
	"画架/画板": "Easel/Drawing Board",
	"彩色铅笔": "Colored pencils",
	"油画棒": "Oil pastels",
	"调色盘/纸/盒": "Palette/paper/box",
	"画布/画框": "Canvas/Frame",
	"画袋/画包": "Drawing Bags",
	"勾线笔": "Line pen",
	"蜡笔": "crayon",
	"揉擦笔揉擦棉": "Rub pen rub cotton",
	"石膏雕塑": "Plaster Sculpture",
	"色卡": "Color Card",
	"拷贝台临摹台": "Copying table",
	"油画刀/刮刀": "Oil painting knife/scraper",
	"粉彩棒色粉笔": "Pastel Stick Color Chalk",
	"刮画纸": "Scratch Paper",
	"写生车/画车": "Sketching car/painting car",
	"风干/速干器": "Air dryer/quick dryer",
	"绘画油壶": "Painting oil pot",
	"喷绘/取色笔": "Inkjet/coloring pen",
	"画杖": "Painting stick",

	"裁剪工具":"Crop tool",
	"美工刀": "Utility knife",
	"剪刀": "Scissors",
	"裁纸刀": "Paper Cutter",
	"开箱器": "Box opener",
	"启信刀拆信刀": "Letter opener",

	"文房四宝":"Four Treasures of the Study",
	"宣纸/制品": "Rice paper/products",
	"毛笔": "Writing brush",
	"文房配件": "Stationery accessories",
	"墨汁/墨锭": "Ink/Ink Stick",
	"文房套装": "Stationery Set",
	"毛笔字帖": "Brush calligraphy copybook",
	"砚台": "Inkstone",
	"书画毛毡": "Painting and calligraphy felt",
	"镇尺/镇纸": "Ruler/Paperweight",
	"水写布": "Water Writing Paper",
	"石章": "Stone Seal",
	"文房印泥": "Ink pad for writing desk",
	"篆刻工具": "Seal carving tools",

	"收纳用品":"Storage supplies",
	"卡套/证件套": "Card holder/ID holder",
	"资料册": "Information Booklet",
	"夹板": "Splint",
	"档案/资料盒": "File/Data Box",
	"文件袋": "Document Bag",
	"文件筐": "File Basket",
	"笔筒": "Pen holder",
	"文件套": "Document holder",
	"抽杆/报告夹": "Pull-out rod/report folder",
	"书立": "Bookends",
	"档案袋": "Portfolio",
	"科目袋": "Subject Bag",
	"风琴包/袋": "Bellows bag",
	"事务包": "Transaction Package",
	"钥匙管理": "Key Management",
	"意见/抽奖箱": "Comments/Raffle Box",
	"书报/展示架": "Books and newspapers/display racks",
	"证件框": "ID Box",
	"快劳夹": "Quick clamp",
	"孔装夹": "Hole clamping",
	"办公纸箱": "Office Carton",
	"桌面文件柜": "Desktop filing cabinet",
	"活页袋": "Loose-leaf bags",
	"名片盒": "Business Card Box",
	"名片册": "Business Card Book",
	"边角夹": "Corner Clip",
	"报纸夹": "Newspaper folder",
	"吊挂夹": "Hanging clip",

	"胶粘用品":"Adhesive Products",
	"透明胶带": "Transparent tape",
	"胶水": "glue",
	"双面胶带": "Double-sided tape",
	"固体胶": "Glue stick",
	"纳米胶带": "Nano tape",
	"美纹纸胶带": "Masking tape",
	"布基胶带": "Duct tape",
	"点点胶": "Glue",
	"警示胶带": "Warning tape",
	"绝缘胶带": "Insulation tape",
	"铝箔胶带": "Aluminum Foil Tape",
	"牛皮纸": "Kraft paper",
	"封箱器": "Carton Sealer",
	"学生白胶": "Student white glue",
	"隐形胶带": "Invisible tape",
	"胶带座": "Tape Dispenser",
	"浆糊": "paste",

	"绘图计算用品":"Graphics and computing supplies",
	"计算器": "calculator",
	"直尺": "ruler",
	"测绘套装": "Surveying and Mapping Package",
	"圆规": "Compasses",
	"皮卷尺/软尺": "Leather measuring tape",
	"钢卷尺": "Steel measuring tape",
	"三角尺": "Triangle ruler",
	"算盘": "abacus",
	"手抄报模板": "Handwritten newspaper template",
	"绘图模板": "Drawing template",
	"比例尺": "scale",
	"量角器": "Protractor",
	"丁字尺": "T-square",

	"装订用品":"Binding Supplies",
	"票夹/长尾夹": "Ticket Clip/Binder Clip",
	"磁扣/磁钉": "Magnetic buckle/magnetic nail",
	"订书机": "stapler",
	"打孔机": "Punch Machine",
	"回形针": "paper clip",
	"推夹器": "Push clip",
	"别针": "pin",
	"大头针": "Pin",
	"起钉器": "Nail Remover",
	"图钉": "Pushpin",
	"三针一钉": "Three needles and one nail",

	"教学实验用品":"Teaching Experiment Supplies",
	"数学教具": "Mathematics teaching aids",
	"地球仪": "Globe",
	"教鞭/手指棒": "Pointer/finger stick",
	"粉笔": "chalk",
	"黑板": "blackboard",
	"板擦": "Eraser",
	"教学模型": "Teaching Model",
	"教学刻章": "Teaching seal carving",
	"教学标本": "Teaching specimens",
	"显微镜": "microscope",

	"手工制作":"Handmade/DIY",
	"彩泥/粘土": "Color clay",
	"折纸/DIY": "Origami/DIY",
	"咕卡套装": "Guka Set",
	"火漆印章": "Wax seal",
	"3D打印笔": "3D printing pen",
	"剪纸/DIY": "Paper Cutting/DIY",
	"奖励刮刮卡": "Rewards Scratch Cards",
	"礼品纸": "Gift Paper",
	"纸雕": "Paper Sculpture",
	"衍纸": "Quilling",


	"电脑办公":"Computer Office",


	"办公用纸":"Office Paper",
	"工程纸": "Engineering Paper",
	"其他标签纸": "Other label paper",
	"彩激纸": "Color laser paper",
	"其他纸类": "Other paper",
	"传真纸": "Fax paper",
	"卡纸": "Paper Jam",
	"铜版纸": "Coated paper",
	"条码标签纸": "Barcode label paper",
	"相片纸": "Photo paper",
	"复印纸": "Copy paper",
	"收银纸": "Cash register paper",

	"外设产品":"Peripheral Products",
	"手机U盘": "Mobile USB flash drive",
	"线缆": "Cables",
	"键盘配件": "Keyboard accessories",
	"平板电脑配件": "Tablet Accessories",
	"机械键盘": "Mechanical keyboard",
	"插座": "socket",
	"笔记本配件": "Laptop accessories",
	"鼠标": "mouse",
	"鼠标垫": "mouse pad",
	"U盘": "USB flash drive",
	"扩展坞": "Docking Station",
	"投屏器": "Screen Projector",
	"移动硬盘": "External Hard Drive",
	"移动固态硬盘": "Portable SSD",
	"硬盘盒": "Hard disk enclosure",
	"UPS电源": "UPS Power Supply",
	"摄像头": "Camera",
	"手写板": "Handwriting tablet",
	"网络仪表": "Network Instrumentation",
	"机柜": "Cabinet",
	"电脑工具": "Computer Tools",
	"电脑清洁": "Computer cleaning",

	"电脑整机":"Computer",
	"闺蜜机": "Girlfriend machine",
	"轻薄本": "Thin and light notebook",
	"二合一平板": "Two-in-one tablet",
	"游戏本": "Gaming Books",
	"商用台式机": "Commercial Desktops",
	"游戏台式机": "Gaming Desktop",
	"台式机": "Desktop",
	"一体机": "All-in-one machine",
	"服务器/工作站": "Server/Workstation",

	"耗材":"Consumables",
	"硒鼓": "toner cartridge",
	"墨盒": "ink cartridge",
	"色带": "ribbon",
	"纸类": "Paper",
	"刻录光盘": "Burning a disc",
	"胶带": "adhesive tape",

	"电脑组件":"Computer components",
	"显示器": "monitor",
	"显示器配件": "Monitor Accessories",
	"SSD固态硬盘": "SSD Solid State Drive",
	"散热器": "heat sink",
	"内存": "Memory",
	"显卡": "Graphics",
	"机箱": "Chassis",
	"组装电脑": "Assembling a computer",
	"电源": "power supply",
	"主板": "Motherboard",
	"硬盘": "harddisk",
	"装机配件": "Installation accessories",
	"CPU": "CPU",
	"刻录机/光驱": "Burner/CD-ROM drive",
	"声卡/扩展卡": "Sound card/expansion card",

	"办公设备":"Office Equipment",
	"打印机": "printer",
	"投影机": "Projector",
	"投影配件": "Projection accessories",
	"家用打印机": "Home Printers",
	"传真设备": "Fax equipment",
	"验钞/点钞机": "Currency detector/counter",
	"扫描仪": "Scanner",
	"条码扫描/采集器": "Barcode Scanner/Collector",
	"复合机": "Compound machine",
	"碎纸机": "Paper Shredder",
	"考勤门禁": "Attendance access control",
	"收银机": "Cash register",
	"激光笔": "Laser Pointer",
	"保险柜/箱": "Safe/Box",
	"打码机": "Coding Machine",
	"安防监控": "Security monitoring",
	"会议音频视频": "Conference Audio and Video",
	"写字板/支架": "Writing board/stand",
	"办公大屏": "Office large screen",
	"会议平板": "Conference Tablet",
	"广告机": "Advertising machine",

	"网络产品":"Network Products",
	"路由器": "router",
	"网线": "Network cable",
	"网络配件": "Network accessories",
	"交换机": "switch",
	"5G/4G上网": "5G/4G Internet Access",
	"网卡": "Network Card",
	"网络盒子": "Network Box",
	"网络存储": "Network storage",

	"游戏设备":"Gaming equipment",
	"手柄/方向盘": "Handle/steering wheel",
	"游戏软件": "Game software",
	"游戏周边": "Game peripherals",
	"游戏机": "Game consoles",
	"游戏耳机": "Gaming Headset",


	"数码":"Digital",


	"数码趋势":"Digital Trends",
	"AI学习机": "AI Learning Machine",
	"开放式耳机": "Open Headphones",
	"智能健康手表": "Smart health watch",
	"Vlog相机": "Vlog Camera",
	"AR眼镜": "AR Glasses",
	"AI手表": "AI Watch",
	"AI眼镜": "AI Glasses",
	"AI相机": "AI Camera",
	"AI耳机": "AI Headphones",
	"AI音箱": "AI Speaker",
	"AI门锁": "AI Door Lock",
	"AI摄像头": "AI Camera",

	"影音娱乐":"Audio and Video Entertainment",
	"直播设备": "Live broadcast equipment",
	"居家艺术音箱": "Home Art Speaker",
	"HIFI音箱": "HIFI Speakers",
	"降噪耳机": "Noise-canceling headphones",
	"耳夹式耳机": "Ear clip headphones",
	"运动耳机": "Sports headphones",
	"眼镜耳机": "Glasses headphones",
	"颈挂式耳机": "Neckband headphones",
	"头戴式耳机": "Headphones",
	"骨传导耳机": "Bone conduction headphones",
	"蓝牙耳机": "Bluetooth Headset",
	"乐器音箱": "Musical Instrument Speakers",
	"舞台音箱": "Stage Speakers",
	"拉杆/K歌": "Pull-up bar/Karaoke",
	"智能音箱": "Smart speakers",
	"音箱/音响": "Speaker/audio",
	"有线耳机": "Wired headphones",
	"麦克风": "microphone",
	"游戏耳机": "Gaming Headset",
	"MP3/MP4": "MP3/MP4",
	"专业音频": "Professional Audio",
	"音频线": "Audio cable",
	"收音机": "radio",

	"数码配件":"Digital accessories",
	"usb风扇": "usb fan",
	"智能手环配件": "Smart bracelet accessories",
	"手持稳定器": "Handheld Stabilizer",
	"镜头附件": "Lens accessories",
	"智能手表配件": "Smart Watch Accessories",
	"耳机配件": "Headphone accessories",
	"电池/充电器": "Battery/Charger",
	"储存卡": "Memory Card",
	"机身附件": "Body accessories",
	"相机包": "Camera Bags",
	"三脚架/云台": "Tripod/Pan Head",
	"滤镜": "Filters",
	"运动相机配件": "Action Camera Accessories",
	"读卡器": "Card reader",
	"相机清洁/贴膜": "Camera cleaning/film",
	"闪光灯/手柄": "Flash/handle",

	"智能设备":"Smart devices",
	"电话手表": "Phone Watch",
	"XR设备": "XR Devices",
	"智能戒指": "Smart Ring",
	"监控摄像": "Surveillance Camera",
	"智能家具": "Smart furniture",
	"智能儿童手表": "Smart Children's Watch",
	"无人机": "Drones",
	"健康监测": "Health Monitoring",
	"智能机器人": "Intelligent Robots",
	"智能配饰": "Smart accessories",
	"智能出行": "Smart Mobility",
	"运动跟踪器": "Motion Tracker",

	"摄影摄像":"Photography and Videography",
	"数码相框": "Digital Photo Frame",
	"影棚器材": "Studio Equipment",
	"微单相机": "Mirrorless camera",
	"拍立得": "Polaroid",
	"数码相机": "digital camera",
	"单反相机": "SLR camera",
	"冲印服务": "Printing service",
	"摄像机": "Camera",
	"户外器材": "Outdoor equipment",

	"电子教育":"E-education",
	"学习机": "Learning Machine",
	"电纸书": "E-book",
	"电子词典": "Electronic Dictionary",
	"录音笔": "Voice Recorder",
	"点读机/笔": "Point reading machine/pen",
	"复读机": "Repeater",
	"翻译机": "Translator",
	"早教益智": "Early Education Puzzles",


	"运动户外":"Sports Outdoor",


	"骑行运动":"Cycling",
	"穿戴装备": "Wearing equipment",
	"山地车": "Mountain Bike",
	"城市自行车": "City Bike",
	"折叠车": "Folding bike",

	"户外装备":"Outdoor Equipment",
	"旅游用品": "Travel Products",
	"户外照明": "Outdoor Lighting",
	"便携桌椅床": "Portable table and chair bed",
	"帐篷/垫子": "Tent/mat",
	"冲浪潜水": "Surfing and diving",
	"野餐/烧烤": "Picnic/BBQ",
	"望远镜": "telescope",
	"户外工具": "Outdoor Tools",
	"军迷用品": "Military Fans Supplies",
	"登山攀岩": "Mountaineering and rock climbing",
	"救援装备": "Rescue equipment",
	"睡袋/吊床": "Sleeping bag/hammock",
	"户外仪表": "Outdoor Instruments",
	"极限户外": "Extreme Outdoor",
	"滑雪装备": "Ski equipment",

	"垂钓用具":"Fishing equipment",
	"鱼饵": "Bait",
	"钓鱼配件": "Fishing accessories",
	"浮漂": "Float",
	"鱼线": "Fishing line",
	"鱼线轮": "Fishing reel",
	"钓箱钓椅": "Fishing box and fishing chair",
	"渔具包": "Fishing gear bag",
	"钓鱼灯": "Fishing Lights",
	"辅助装备": "Auxiliary equipment",
	"钓竿": "fishing rod",

	"体育用品":"Sporting Goods",
	"羽毛球拍": "Badminton racket",
	"棋牌麻将": "Mahjong",
	"篮球": "basketball",
	"乒乓球拍": "Table tennis racket",
	"轮滑滑板": "Roller Skateboard",
	"足球": "football",
	"网球拍": "Tennis racket",
	"田径鞋": "Track and field shoes",
	"排球": "volleyball",
	"台球桌": "Pool Table",
	"高尔夫球杆套杆": "Golf Club Sets",
	"棒球": "baseball",

	"健身训练":"Fitness training",
	"哑铃": "dumbbel",
	"瑜伽用品": "Yoga supplies",
	"其他器械": "Other equipment",
	"武术搏击": "Martial Arts Fighting",
	"跑步机": "treadmill",
	"动感单车": "Spinning bike",
	"甩脂机": "Fat Shaking Machine",
	"综合训练器": "Comprehensive trainer",
	"椭圆机": "Elliptical Machine",
	"仰卧板/收腹机": "Supine board/abdominal machine",
	"踏步机": "Stepper",


	"箱包":"Luggage",


	"功能箱包":"Functional bags",
	"旅行配件": "Travel accessories",
	"护脊书包": "Back protection backpack",
	"行李箱": "suitcase",
	"儿童行李箱": "Children's suitcase",
	"儿童书包": "Children's school bags",
	"旅行包": "Travel Bag",

	"潮流女包":"Fashion women's bags",
	"单肩包": "Shoulder Bag",
	"迷你包": "Mini Bag",
	"手提包": "handbag",
	"女士皮带": "Women's belts",
	"帆布包": "Canvas bag",
	"斜挎包": "Crossbody Bags",

	"精品男包":"Men's Bags",
	"男士手机包": "Men's mobile phone bag",
	"男士皮带": "Men's Belts",
	"单肩斜挎包": "Shoulder bag",
	"男士双肩包": "Men's Backpack",
	"男士胸包": "Men's chest bag",
	"商务公文包": "Business Briefcase",


	"钟表珠宝":"Watches and Jewellery",


	"腕表":"Watch",
	"国表": "National table",
	"钟表配件": "Watch accessories",
	"挂钟": "wall clock",
	"闹钟": "Alarm clock",
	"智能手表": "Smartwatch",
	"儿童手表": "Children's Watches",

	"饰品":"accessories",
	"手链/脚链": "Bracelets/Anklets",
	"项链": "necklace",
	"耳饰": "earring",
	"饰品配件": "Jewelry Accessories",
	"时尚戒指": "Fashion Rings",
	"胸针": "brooch",
	"发饰/发卡": "Hair accessories/hairpins",
	"水晶玛瑙": "Crystal Agate",
	

	"奢侈品":"Luxury",


	"男包":"Men's Bags",

	"女包":"Women's Bags",

	"饰品":"accessories",

	"配饰":"Accessories",


	"机械设备":"Mechanical equipment",


	"运输工具":"Transportation tools",
	"三轮车": "Tricycle",
	"货车": "truck",
	"拖拉机": "Tractor",
	"飞行器": "Aerocraft",

	"全部":"All",
}