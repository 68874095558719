export const lang = {
	"公司地址": "中国北京市朝阳区保利国际广场T1栋9楼",
	"公司介绍": "公司介绍",
	"品类介绍": "品类介绍",
	"服务能力": "服务能力",
	"需求反馈": "需求反馈",
	"合作伙伴": "合作伙伴",
	"联系我们": "联系我们",
	"全球优质供应链服务商": "全球优质供应链服务商",
	"为全球品牌和企业提供定制化产品解决方案": "为全球品牌和企业提供定制化产品解决方案",
	"华汾公司介绍": "华汾公司介绍",

	"company-profile-title1":"华汾（北京）国际商贸有限公司",
	"company-profile1":"是一家集实业产品研发、生产、设计、品牌孵化、全球品牌营销、进出口贸易销售为一体的综合性国际贸易企业。我们通过利用自研Web 3.0去中心化技术提供顶级互联网营销和数字管理服务。我们擅长在全球贸易网络中协调智能数字战略，为品牌和企业提供卓越的价值。",

	"company-profile-title2":"战略优势：",
	"company-profile2":"我们依托全国航运优势资源，遍布89座城市和90座机场的强大广告网络，有效覆盖了90%以上的城市地标。这种广泛的影响力使我们能够与中国14亿庞大的人口建立联系，显著提高了品牌知名度和影响力。",

	"company-profile-title3":"创新产品",
	"company-profile3":"在华汾，我们为推动技术进步和创新而感到自豪。我们向全球消费者推荐具有国际文化意义的高品质生活方式产品，在世界舞台上展示中国的贸易发展和创新能力。",

	"company-profile-title4":"我们的产品系列：",
	"company-profile4":"华汾经营三个不同的产品系列",


	"company-profile-title5":"1.专有品牌",
	"company-profile5":" - 华汾开发和拥有的品牌，体现了我们对创新和品质的执着追求",

	"company-profile-title6":"2.国际授权品牌",
	"company-profile6":" - 我们拥有全球知名品牌的经销权，确保质量和正品属性。",

	"company-profile-title7":"3.代理品牌",
	"company-profile7":" - 我们代理并进行经营的品牌，通过我们的专业知识来扩大其影响力。",

	"company-profile-title8":"综合产业链：",
	"company-profile8":"我公司拥有集产品研发、生产、销售、物流、营销为一体的完整产业链。这种全方位的运营模式确保了业务的顺畅开展以及卓越的服务品质，这与我们为全球客户及合作伙伴提供优质产品和解决方案的目标相契合。",


	"我们能提供的品类": "提供品类",
	"个护清洁": "个护清洁",
	"清洁工具": "清洁工具",
	"家庭清洁": "家庭清洁",
	"衣物清洁": "衣物清洁",
	"洗发护发": "洗发护发",
	"身体护理": "身体护理",
	"纸品湿巾": "纸品湿巾",
	"口腔护理": "口腔护理",
	"驱蚊驱虫": "驱蚊驱虫",
	"食品酒饮": "食品酒饮",
	"乳饮冰品": "乳饮冰品",
	"中外名酒": "全球名酒",
	"方便速食": "方便速食",
	"粮油调味": "粮油调味",
	"咖啡": "咖啡",
	"休闲食品": "休闲食品",
	"美妆护肤": "美妆护肤",
	"面部护肤": "面部护肤",
	"香水": "香水",
	"彩妆": "彩妆",
	"家电": "家电",
	"家电配件": "家电配件",
	"厨房小电": "厨房小电",
	"清洁电器": "清洁电器",
	"个护健康": "个护健康",
	"空调": "空调",
	"冰箱": "冰箱",
	"电视": "电视",
	"厨卫大电": "厨卫大电",
	"视听影音": "视听影音",
	"家居厨具": "家居厨具",
	"火机烟具": "火机烟具",
	"餐具": "餐具",
	"烹饪厨具": "烹饪厨具",
	"水具酒具": "水具酒具",
	"生活日用": "生活日用",
	"居家布艺": "居家布艺",
	"床上用品": "床上用品",
	"汽摩生活": "汽摩生活",
	"电动车": "电动车",
	"摩托车": "摩托车",
	"农用机械": "农用机械",
	"搬运设备": "搬运设备",
	"装饰用品": "装饰用品",
	"派对用品": "派对用品",
	"母婴": "母婴",
	"奶粉": "奶粉",
	"尿布湿巾": "尿布湿巾",
	"洗护用品": "洗护用品",
	"营养辅食": "营养辅食",
	"喂养工具": "喂养工具",
	"欢迎访问 Go2Pie商城，查找商品": "欢迎访问 Go2Pie商城，查找商品",
	"我们的服务能力": "我们的服务能力",
	"贸易": "贸易",
	"自主工厂生产及国内外品牌直供": "自主生产与全球品牌",
	"自研国际自由贸易平台Goπ": "自研国际自由贸易平台Goπ",
	"品牌授权及合作线下门店": "品牌授权及合作线下门店",
	"国内外各大品牌经销/代理商": "国内外各大品牌经销/代理商",
	"国际线上交易支付管理系统": "国际线上交易支付管理系统",
	"营销": "营销",
	"整合营销推广解决方案": "整合营销推广解决方案",
	"媒体营销推广": "媒体营销推广",
	"社交平台运营": "社交平台运营",
	"电商平台运营": "电商平台运营",
	"内容创意及制作": "内容创意及制作",
	"物流配送": "物流配送",
	"顶级物流体系及航运支持": "顶级物流体系及航运支持",
	"全国物流和冷链配送": "全国物流和冷链配送",
	"国际供应及国际运输": "国际供应及国际运输",
	"提供销售通路搭建、企业集采及一件代发等全方位服务": "提供销售通路搭建、企业集采及一件代发等全方位服务",
	"需求反馈收集": "需求反馈收集",
	"我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系": "我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系",
	"提交需求反馈表单": "提交需求反馈表单",
	"集团合作伙伴": "集团合作伙伴",
	"集团": "集团",
	"合作伙伴及品牌": "合作伙伴及品牌",
	"合作媒体": "合作媒体",
	"华汾版权所有": "华汾版权所有",

	"收起": "收起",
	"展开": "展开",


	"食品":"食品",


	"咖啡":"咖啡",
	"胶囊咖啡": "胶囊咖啡",
	"黑咖啡": "黑咖啡",
	"白咖啡": "白咖啡",
	"咖啡豆/粉": "咖啡豆/粉",
	"滴咖啡": "滴漏咖啡",
	"咖啡液": "液态咖啡",
	"咖啡伴侣": "咖啡伴侣",
	"速溶咖啡": "速溶咖啡",
	"即饮咖啡": "即饮咖啡",
	"速溶黑咖啡": "速溶黑咖啡",
	"冻干咖啡": "冻干咖啡",
	"三倍的": "三倍的",

	"可饮用谷物":"可饮用谷物",
	"大米": "大米",
	"煎堆": "芝麻球",
	"奶茶": "奶茶",
	"豆浆": "豆浆",
	"莲藕淀粉": "藕粉",
	"全麦谷物": "全麦谷物",
	"谷物粉": "谷物粉",
	"水果麦片": "水果麦片",
	"代餐摇床": "代餐奶昔",
	"成人奶粉": "成人奶粉",
	"霍尼": "蜂蜜",

	"牛奶饮料和冰淇淋":"牛奶饮料和冰淇淋",
	"闪闪发光的水": "苏打水",
	"碳酸饮料": "碳酸饮料",
	"能量饮料": "能量饮料",
	"乳酸菌饮料": "乳酸菌饮料",
	"果蔬汁": "果蔬汁",
	"茶饮料": "茶饮料",
	"酸奶": "酸奶",
	"纯牛奶": "纯牛奶",
	"饮用水": "饮用水",
	"冰淇淋": "冰淇淋",

	"中外著名葡萄酒":"全球名酒",
	"洋酒": "洋酒",
	"白酒类": "白酒类",
	"啤酒": "啤酒",
	"葡萄酒": "葡萄酒",
	"黄酒/保健酒": "黄酒/保健酒",

	"谷物和油调味品":"谷物和油调味品",
	"中国调味品": "中国调味品",
	"西餐": "西餐",
	"配菜": "配菜",
	"东南亚调味品": "东南亚调味品",
	"花生菜籽油": "花生菜籽油",
	"葵花籽油": "葵花籽油",
	"米油": "米油",
	"橄榄油": "橄榄油",
	"茶油": "茶油",
	"谷物": "谷物",
	"粉丝": "粉丝",
	"面粉": "面粉",

	"南北干货":"南北干货",
	"银耳": "银耳",
	"扇贝": "扇贝",
	"干蔬菜": "干蔬菜",
	"真菌": "真菌",
	"龙眼": "龙眼",
	"莲子": "莲子",
	"干蘑菇": "干蘑菇",
	"干海产品": "干海产品",
	"有机干货": "有机干货",

	"方便快餐":"方便快餐",
	"午餐肉": "午餐肉",
	"自热食品": "自热食品",
	"米粉": "米粉",
	"速溶汤": "速溶汤",
	"火腿": "火腿",
	"蜗牛米粉": "螺蛳粉",
	"方便面": "方便面",
	"粽子": "粽子",
	"青团": "青团",

	"零食":"零食",
	"低热量零食": "低热量零食",
	"饼干和糕点": "饼干和糕点",
	"愚蠢的": "干肉条",
	"坚果和烤坚果": "坚果和烤坚果",
	"蜜饯水果": "蜜饯水果",
	"糖果": "糖果",
	"零食礼品包": "零食礼品包",
	"麻辣豆腐干": "麻辣豆腐干",
	"红烧鸡爪": "红烧鸡爪",
	"薯片膨化": "薯片膨化",
	"果冻布丁": "果冻布丁",
	"巧克力": "巧克力",

	"茶":"茶",
	"菊花茶": "菊花茶",
	"绿茶": "绿茶",
	"红茶": "红茶",
	"普洱茶": "普洱茶",
	"乌龙茶": "乌龙茶",
	"茉莉花茶": "茉莉花茶",
	"健康茶": "健康茶",
	"白茶": "白茶",
	"西湖龙井": "西湖龙井",
	"铁观音": "铁观音",
	"红茶": "红茶",


	"生鲜":"生鲜",

	
	"快速烘焙":"快速烘焙",
	"方便面": "方便面",
	"粤式早茶": "粤式早茶",
	"烘焙半成品": "烘焙半成品",
	"面条": "面条",
	"饺子蒸饺和炸饺子": "蒸饺和煎饺",
	"馄饨": "馄饨",
	"奶酪黄油": "奶酪黄油",
	"蛋糕和甜点": "蛋糕和甜点",
	"汤圆元宵": "汤圆元宵",
	"披萨": "披萨",
	"蛋挞": "蛋挞",
	"低温粽子": "低温粽子",

	"预先准备的菜肴":"预制菜",
	"油炸食品": "油炸食品",
	"便餐": "便餐",
	"熟食": "熟食",
	"火锅配料": "火锅配料",
	"培根香肠": "培根香肠",
	"半成品菜肴": "半成品菜",
	"海鲜配菜": "海鲜配菜",
	"当地特产": "当地特产",
	"一个人": "单人餐",

	"肉类和家禽产品":"肉类和家禽产品",
	"冷肉": "冷肉",
	"牛排": "牛排",
	"牛肉": "牛肉",
	"鸡": "鸡",
	"鸡蛋": "鸡蛋",
	"猪肉": "猪肉",
	"羊肉": "羊肉",
	"鸭子": "鸭子",

	"蔬菜":"蔬菜",
	"大豆制品": "大豆制品",
	"根状茎": "根茎",
	"红薯": "红薯",
	"有机时令蔬菜": "有机时令蔬菜",
	"玉米": "玉米",
	"茄瓜": "茄瓜",
	"绿叶蔬菜": "绿叶蔬菜",
	"洋葱、姜、大蒜和胡椒": "洋葱、姜、大蒜和胡椒",
	"冷冻蔬菜": "冷冻蔬菜",
	"新鲜蘑菇": "新鲜蘑菇",

	"海鲜": "海鲜",
	"新鲜": "新鲜",
	"鲑鱼": "鲑鱼",
	"鱼": "鱼",
	"小龙虾": "小龙虾",
	"小虾": "小虾",
	"贝类": "贝类",
	"大闸蟹": "大闸蟹",
	"蟹": "蟹",
	"海参": "海参",

	"新鲜水果":"新鲜水果",
	"进口水果": "进口水果",
	"桃、李、杏": "桃、李、杏",
	"苹果": "苹果",
	"榴莲": "榴莲",
	"芒果": "芒果",
	"梨": "梨",
	"樱桃": "樱桃",
	"猕猴桃": "猕猴桃",
	"荔枝": "荔枝",
	"蓝莓": "蓝莓",
	"橙色": "橙子",
	"橙色/橘黄色": "橙子/柑橘",
	"椰青": "椰青",
	"水果礼盒/礼券": "水果礼盒/礼券",


	"个护清洁":"个护清洁",


	"纸品湿巾":"纸品湿巾",
	"抽纸": "抽纸",
	"手帕纸": "手帕纸",
	"卷纸": "卷纸",
	"湿巾": "湿巾",
	"厨房纸巾": "厨房纸巾",

	"洗发护发":"洗发护发",
	"洗发水": "洗发水",
	"护发素": "护发素",
	"发膜": "发膜",
	"生发液": "生发液",
	"护发精油": "护发精油",
	"洗护套装": "洗护套装",

	"身体护理":"身体护理",
	"精油": "精油",
	"沐浴露": "沐浴露",
	"男士沐浴": "男士沐浴",
	"儿童沐浴": "儿童沐浴",
	"香皂": "香皂",
	"洗手液": "洗手液",
	"美体/工具": "美体/工具",
	"护手霜": "护手霜",
	"浴盐": "浴盐",
	"身体护理套": "身体护理套",
	"花露水": "花露水",

	"口腔护理":"口腔护理",
	"牙膏": "牙膏",
	"儿童口腔": "儿童口腔护理",
	"牙刷": "牙刷",
	"漱口水": "漱口水",
	"牙线/牙线棒": "牙线/牙线棒",
	"口喷": "口喷",
	"牙贴": "牙贴",

	"衣物清洁":"衣物清洁",
	"洗衣液": "洗衣液",
	"洗衣粉": "洗衣粉",
	"凝珠": "凝珠",
	"洗衣皂": "洗衣皂",
	"柔顺剂": "柔顺剂",
	"除菌液": "除菌液",

	"女性护理":"女性护理",
	"卫生巾": "卫生巾",
	"护垫": "护垫",
	"棉条": "棉条",
	"裤型卫生巾": "裤型卫生巾",
	"私处护理": "私处护理",

	"驱蚊驱虫":"驱蚊驱虫",
	"灭蚁": "灭蚁",
	"杀蟑": "杀蟑",
	"驱鼠": "驱鼠",
	"除满": "除满",
	"驱蝇": "驱蝇",

	"清洁工具":"清洁工具",
	"拖把": "拖把",
	"垃圾桶": "垃圾桶",
	"垃圾袋": "垃圾袋",
	"清洁刷具": "清洁刷具",
	"抹布/百洁布": "抹布/百洁布",
	"手套/鞋套": "手套/鞋套",
	"一次性清洁用品": "一次性清洁用品",
	"扫把": "扫把",
	"脸盆/水桶": "脸盆/水桶",

	"发型设计":"发型设计",
	"发胶": "发胶",
	"发蜡/泥": "发蜡/泥",
	"定型啫喱": "定型啫喱",
	"染发": "染发",
	"假发": "假发",
	"弹力素": "弹力素",
	"美发工具": "美发工具",
	"蓬蓬粉": "蓬蓬粉",

	"家庭清洁":"家庭清洁",
	"油污净": "油污净",
	"洗洁精": "洗洁精",
	"洁厕剂": "洁厕剂",
	"消毒液": "消毒液",
	"地板清洁剂": "地板清洁剂",
	"除湿干燥剂": "除湿干燥剂",

	"成人护理":"成人护理",
	"成人纸尿裤": "成人纸尿裤",
	"成人拉拉裤": "成人拉拉裤",
	"成人护理垫": "成人护理垫",
	"成人纸尿垫": "成人纸尿垫",


	"美妆护肤":"美妆护肤",


	"面部护肤":"面部护肤",
	"防晒": "防晒",
	"眼霜眼部精华": "眼霜眼部精华",
	"护肤": "护肤",
	"洁面": "洁面",
	"化妆水": "化妆水",
	"卸妆": "卸妆",
	"润唇膏": "润唇膏",
	"眼膜": "眼膜",
	"T区护理": "T区护理",
	"唇膜唇部精华": "唇膜唇部精华",

	"彩妆":"彩妆",
	"隔离霜/妆前乳": "隔离霜/妆前乳",
	"粉底液/膏": "粉底液/膏",
	"唇彩唇蜜/唇釉": "唇彩唇蜜/唇釉",
	"气垫": "气垫",
	"BB霜": "BB霜",
	"蜜粉/散粉": "蜜粉/散粉",
	"眉笔/眉粉": "眉笔/眉粉",
	"粉饼": "粉饼",
	"美甲": "美甲",
	"眼影": "眼影",
	"眼线笔/眼线液": "眼线笔/眼线液",
	"遮瑕": "遮瑕",
	"腮红/胭脂": "腮红/胭脂",
	"睫毛膏/睫毛液": "睫毛膏/睫毛液",
	"CC霜": "CC霜",
	"修容/高光/阴影": "修容/高光/阴影",
	"唇笔/唇线笔": "唇笔/唇线笔",
	"女士香水": "女士香水",
	"男士香水": "男士香水",

	"美妆工具":"美妆工具",
	"美甲工具": "美甲工具",
	"化妆棉": "化妆棉",
	"化妆刷": "化妆刷",
	"粉扑/洗脸扑": "粉扑/洗脸扑",
	"假睫毛": "假睫毛",
	"修眉刀": "修眉刀",
	"双眼皮贴": "双眼皮贴",
	"睫毛夹": "睫毛夹",
	"美妆工具": "美妆工具",
	"吸油纸": "吸油纸",


	"家居厨具":"家居厨具",


	"生活日用":"生活日用",
	"香薰/蜡烛": "香薰/蜡烛",
	"衣架": "衣架",
	"指甲刀": "指甲刀",
	"纸巾盒": "纸巾盒",
	"雨伞雨具": "雨伞雨具",
	"日杂小件": "日杂小件",
	"梳子": "梳子",
	"浴室置物架": "浴室置物架",
	"移动马桶": "移动马桶",
	"保暖防护": "保暖防护",
	"洗漱杯": "洗漱杯",
	"搓澡巾": "搓澡巾",
	"马桶刷": "马桶刷",
	"净化除味": "净化除味",
	"化妆镜": "化妆镜",

	"水具酒具":"水具酒具",
	"保温杯": "保温杯",
	"玻璃杯": "玻璃杯",
	"塑料杯": "塑料杯",
	"陶瓷/马克杯": "陶瓷/马克杯",
	"保温壶": "保温壶",
	"运动水壶": "运动水壶",
	"焖烧杯": "焖烧杯",
	"酒杯/酒具": "酒杯/酒具",

	"烹饪锅具":"烹饪锅具",
	"炒锅": "炒锅",
	"平底锅/煎锅": "平底锅/煎锅",
	"汤锅": "汤锅",
	"蒸锅": "蒸锅",
	"奶锅": "奶锅",
	"高压锅": "高压锅",
	"火锅": "火锅",

	"餐具":"餐具",
	"碗": "碗",
	"盘/碟": "盘/碟",
	"筷子": "筷子",
	"刀/叉/勺": "刀/叉/勺",
	"果盘/果篮": "果盘/果篮",
	"一次性用品": "一次性用品",

	"厨房配件":"厨房配件",
	"上水器": "上水器",
	"真空机": "真空机",
	"厨房储物器皿": "厨房储物器皿",
	"壁挂置物架": "壁挂置物架",
	"落地置物架": "落地置物架",
	"台面置物架": "台面置物架",
	"保鲜盒": "保鲜盒",
	"饭盒/提锅": "饭盒/提锅",
	"调料器皿": "调料器皿",
	"厨房秤": "厨房秤",
	"烘培用具": "烘培用具",
	"厨房DIY/小工具": "厨房DIY/小工具",

	"火机烟具":"火机烟具",
	"煤油打火机": "煤油打火机",
	"一次性打火机": "一次性打火机",
	"火机油": "火机油",
	"充电打火机": "充电打火机",
	"烟嘴": "烟嘴",
	"烟斗": "烟斗",
	"雪茄用品": "雪茄用品",
	"戒烟贴": "戒烟贴",
	"烟灰缸": "烟灰缸",

	"茶具/咖啡具":"茶具/咖啡具",
	"茶杯": "茶杯",
	"茶壶": "茶壶",
	"茶具配件": "茶具配件",
	"茶盘": "茶盘",
	"咖啡杯": "咖啡杯",
	"旅行茶具": "旅行茶具",
	"咖啡具配件": "咖啡具配件",

	"刀剪菜板":"刀剪菜板",
	"砧板": "砧板",
	"菜刀": "菜刀",
	"瓜果刀/刨": "瓜果刀/刨",
	"剪刀": "剪刀",
	"多功能到": "多功能刀",

	"地毯底垫":"地毯底垫",
	"浴室地垫": "浴室地垫",
	"入户地垫": "入户地垫",
	"商用地毯": "商用地毯",
	"床边毯": "床边毯",
	"厨房地垫": "厨房地垫",
	"客厅地毯": "客厅地毯",

	"收纳用品":"收纳用品",
	"收纳洞洞板": "收纳洞洞板",
	"首饰盒": "首饰盒",
	"夹缝收纳柜": "夹缝收纳柜",
	"化妆品收纳盒": "化妆品收纳盒",
	"搬家纸箱": "搬家纸箱",
	"收纳箱": "收纳箱",
	"鞋盒": "鞋盒",
	"搬家袋": "搬家袋",
	"桌面收纳盒": "桌面收纳盒",
	"内衣收纳盒": "内衣收纳盒",
	"真空收纳袋": "真空收纳袋",
	"旅行收纳袋": "旅行收纳袋",
	"化妆品收纳包": "化妆品收纳包",
	"手办收纳盒": "手办收纳盒",
	"收纳柜": "收纳柜",

	"居家布艺":"居家布艺",
	"毛巾浴巾": "毛巾浴巾",
	"沙发垫": "沙发垫",
	"门帘": "门帘",
	"一次性洗脸巾": "一次性洗脸巾",
	"飘窗垫": "飘窗垫",
	"干发帽": "干发帽",
	"窗纱": "窗纱",
	"蒲团": "蒲团",
	"毛毯": "毯子",
	"窗帘": "窗帘",
	"抱枕靠垫": "抱枕靠垫",
	"桌布": "桌布",

	"床上用品":"床上用品",
	"枕头": "枕头",
	"床垫床褥": "床垫床褥",
	"床单床笠": "床单床笠",
	"蚊帐": "蚊帐",
	"棉花被": "棉花被",
	"四件套": "四件套",
	"纤维被": "纤维被",
	"蚕丝被": "蚕丝被",
	"羽绒服被": "羽绒服被",
	"电热毯": "电热毯",
	"凉席": "凉席",
	"羊毛被": "羊毛被",
	"水暖毯": "水暖毯",
	"凉感被": "凉感被",


	"家电":"家电",


	"环境电器":"环境电器",
	"电风扇": "电风扇",
	"净水器": "净水器",
	"空气净化器": "空气净化器",
	"茶吧机": "茶吧机",
	"除湿机": "除湿机",
	"取暖器": "取暖器",
	"电暖桌": "电暖桌",
	"电油灯": "电油灯",
	"软水机": "软水机",
	"即热饮水机": "即热饮水机",
	"加热净水器": "加热净水器",
	"全屋净水": "全屋净水器",
	"加湿净化器": "加湿净化器",
	"无雾加湿器": "无雾加湿器",
	"移动地暖": "移动地暖",

	"空调":"空调",
	"变频空调": "变频空调",
	"新风空调": "新风空调",
	"新一级能效": "新一级能效",
	"空调柜机": "空调柜机",
	"空调挂机": "空调挂机",
	"中央空调": "中央空调",

	"冰箱":"冰箱",
	"多门冰箱": "多门冰箱",
	"对开门冰箱": "对开门冰箱",
	"十字对开门": "十字对开门冰箱",
	"双门冰箱": "双门冰箱",
	"单门冰箱": "单门冰箱",
	"三门冰箱": "三门冰箱",
	"冷柜/冰吧": "冷柜/冰吧",
	"红酒柜": "红酒柜",

	"洗衣机":"洗衣机",
	"洗烘一体机": "洗烘一体机",
	"滚筒洗衣机": "滚筒洗衣机",
	"迷你洗衣机": "迷你洗衣机",
	"烘干机": "烘干机",

	"电视":"电视",
	"K歌电视": "K歌电视",
	"艺术电视": "艺术电视",
	"会议电视": "会议电视",
	"游戏电视": "游戏电视",
	"85吋电视": "85寸电视",
	"98吋电视": "98寸电视",
	"75吋电视": "75寸电视",
	"miniled电视": "迷你LED电视",
	"电视挂架": "电视挂架",
	"电视推车": "电视推车",
	"电视遥控器": "电视遥控器",

	"厨房小电":"厨房小电",
	"电饭煲": "电饭煲",
	"电水壶热水瓶": "电水壶热水瓶",
	"电磁炉": "电磁炉",
	"破壁机": "破壁机",
	"电压力锅": "电压力锅",
	"养生壶": "养生壶",
	"电饼档": "电饼档",
	"微波炉": "微波炉",
	"空气炸锅": "空气炸锅",
	"咖啡机": "咖啡机",
	"电烤箱": "电烤箱",
	"三明治早餐机": "三明治早餐机",
	"果蔬净化器": "果蔬净化器",
	"炒菜机器人": "炒菜机器人",
	"电动榨汁杯": "电动榨汁杯",
	"电煮锅": "电煮锅",
	"刀筷消毒机": "刀筷消毒机",
	"电热火锅": "电热火锅",
	"电蒸锅": "电蒸锅",
	"厨师机": "厨师机",
	"绞肉机": "绞肉机",

	"厨卫大电":"厨卫大电",
	"油烟机": "油烟机",
	"灶具": "灶具",
	"电热水具": "电热水具",
	"燃气热水器": "燃气热水器",
	"消毒柜": "消毒柜",
	"洗碗机": "洗碗机",
	"壁挂炉": "壁挂炉",
	"空气能热水器": "空气能热水器",
	"太阳能热水器": "太阳能热水器",
	"嵌入式厨电": "嵌入式厨电",
	"电热水龙头": "电热水龙头",
	"集成水槽": "集成水槽",
	"集成灶": "集成灶",

	"个护健康":"个护健康",
	"干衣机": "干衣机",
	"剃须刀": "剃须刀",
	"按摩器": "按摩器",
	"电吹风": "电吹风",
	"电动牙叔": "电动牙刷",
	"理发器": "理发器",
	"美容器": "美容仪",
	"卷/直发器": "卷/直发器",
	"冲牙器": "冲牙器",
	"按摩椅": "按摩椅",
	"足浴盆": "足浴盆",
	"剃/脱毛器": "剃/脱毛器",
	"潮流护理电器": "潮流护理电器",
	"洗手机": "洗手机",
	"手持挂烫机": "手持挂烫机",

	"家电配件":"家电配件",
	"燃气灶支架": "燃气灶支架",
	"个护健康配件": "个护健康配件",
	"电视支架": "电视支架",
	"冰箱配件": "冰箱配件",
	"电视艺术支架": "电视艺术支架",
	"吸尘器支架": "吸尘器支架",
	"热水器镁棒": "热水器镁棒",
	"聚水罩": "聚水罩",
	"冰箱门封条": "冰箱门封条",
	"生活电器配件": "生活电器配件",
	"洗衣机配件": "洗衣机配件",
	"空调挡风板": "空调挡风板",
	"电视配件": "电视配件",
	"空调遥控器": "空调遥控器",
	"空调配件": "空调配件",
	"冰箱底座架": "冰箱底座架",
	"洗衣机底座": "洗衣机底座",
	"空调支架": "空调支架",
	"厨房小电配件": "厨房小电配件",
	"厨卫大电配件": "厨卫大电配件",
	"洗衣机排水管": "洗衣机排水管",

	"清洁电器":"清洁电器",
	"扫地机器人": "扫地机器人",
	"蒸汽电动拖把": "蒸汽电动拖把",
	"除螨仪": "除螨仪",
	"擦窗机器人": "擦窗机器人",
	"洗地机": "洗地机",

	"视听影音":"视听影音",
	"麦克风": "麦克风",
	"KTV音响": "KTV音响",
	"家庭影响": "家庭音响",
	"功放": "功放",
	"迷你音响": "迷你音响",
	"电视音响": "电视音响",


	"家居家装":"家居家装",


	"五金工具":"五金工具",
	"家用五金": "家用五金",
	"电钻": "电钻",
	"螺丝刀": "螺丝刀",
	"增压泵": "增压泵",
	"水平仪": "Level",
	"工具套装": "工具套装",
	"电锯": "电锯",
	"角磨机": "角磨机",
	"测距仪": "测距仪",
	"五金工具箱": "五金工具箱",
	"水平尺": "水平尺",
	"家用电梯": "家用电梯",
	"购物车": "购物车",
	"机械锁": "机械锁",
	"智能锁": "智能锁",

	"电工电料":"电工电料",
	"开关插座": "开关插座",
	"智能家居": "智能家居",
	"电料配件": "电料配件",
	"智能开关": "智能开关",
	"电线/电缆": "电线/电缆",
	"配电箱/断路器": "配电箱/断路器",
	"电气控制": "电气控制",
	"智能窗帘": "智能窗帘",

	"书房家具":"书房家具",
	"电脑桌": "电脑桌",
	"电脑椅": "电脑椅",
	"书架": "书架",
	"书桌": "书桌",
	"升降桌": "升降桌",
	"红木书桌": "红木书桌",

	"餐厅家具":"餐厅家具",
	"岩板餐桌": "岩板餐桌",
	"实木餐桌": "实木餐桌",
	"岛台餐桌": "岛台餐桌",
	"折叠餐桌": "折叠餐桌",
	"餐车": "餐车",
	"餐边柜": "餐边柜",
	"餐椅": "餐椅",
	"红木餐桌": "红木餐桌",
	"红木餐边桌": "红木餐边桌",

	"阳台储物":"阳台储物",
	"飘窗桌": "飘窗桌",
	"层架/置物架": "层架/置物架",
	"智能晾衣架": "智能晾衣架",
	"阳台柜": "阳台柜",
	"衣帽架": "衣帽架",
	"落地晾衣架": "落地晾衣架",
	"隐藏晾衣架": "隐藏晾衣架",
	"鞋架": "鞋架",
	"储物/收纳": "储物/收纳",
	"手摇晾衣架": "手摇晾衣架",
	"墙面搁架": "墙面搁架",
	"泳池躺椅": "泳池躺椅",

	"厨房卫浴":"厨房卫浴",
	"厨房配件": "厨房配件",
	"淋浴花洒": "淋浴花洒",
	"龙头": "龙头",
	"厨卫挂件": "厨卫挂件",
	"水槽": "水槽",
	"马桶": "马桶",
	"陶瓷件组套": "陶瓷件套装",
	"浴室柜": "浴室柜",
	"浴霸": "浴霸",
	"净水软水": "净水软水",
	"淋浴房": "淋浴房",
	"智能马桶盖": "智能马桶圈",
	"浴缸": "浴缸",
	"沐浴桶": "沐浴桶",
	"垃圾处理器": "垃圾处理器",

	"客厅家具":"客厅家具",
	"边桌/茶几": "边桌/茶几",
	"布艺沙发": "布艺沙发",
	"皮艺沙发": "皮艺沙发",
	"鞋柜": "鞋柜",
	"沙发床": "沙发床",
	"电视柜": "电视柜",
	"穿衣镜": "穿衣镜",
	"实木沙发": "实木沙发",
	"屏风": "屏风",
	"懒人沙发": "懒人沙发",
	"功能沙发": "功能沙发",
	"酒柜": "酒柜",
	"红木沙发": "红木沙发",
	"壁炉": "壁炉",
	"红木电视柜": "红木电视柜",

	"庭院户外":"庭院户外",
	"折叠穿": "折叠穿",
	"遮阳伞": "遮阳伞",
	"充气家具": "充气家具",
	"遮阳棚": "遮阳棚",
	"家用梯": "家用梯",
	"户外桌椅": "户外桌椅",
	"木屋凉亭": "木屋凉亭",
	"秋千": "秋千",
	"岗亭岗台": "岗亭岗台",
	"户外沙发": "户外沙发",
	"吊篮": "吊篮",

	"卧室家具":"卧室家具",
	"实木床": "实木床",
	"皮艺床": "皮艺床",
	"衣柜": "衣柜",
	"实木衣柜": "实木衣柜",
	"床头柜": "床头柜",
	"床垫": "床垫",
	"乳胶床垫": "乳胶床垫",
	"弹簧床垫": "弹簧床垫",
	"简易衣柜": "简易衣柜",
	"斗柜": "斗柜",
	"布艺床": "布艺床",
	"床尾凳": "床尾凳",
	"红木床": "红木床",
	"红木衣柜": "红木衣柜",
	"梳妆台/凳": "梳妆台/凳",

	"儿童家具":"儿童家具",
	"高低床": "双层床",
	"绘本架": "绘本架",
	"儿童床": "儿童床",
	"边床": "边床",
	"儿童桌椅套装": "儿童桌椅套装",
	"儿童椅": "儿童椅",
	"儿童床垫": "儿童床垫",
	"儿童桌": "儿童桌",
	"儿童书桌": "儿童书桌",
	"儿童衣柜": "儿童衣柜",
	"儿童沙发": "儿童沙发",
	"儿童收纳储物": "儿童收纳储物",
	"儿童凳": "儿童凳",
	"儿童玩具桌": "儿童玩具桌",

	"商业办公":"商业办公",
	"适老家具": "适老家具",
	"餐饮家具": "餐饮家具",
	"会议桌": "会议桌",
	"货架": "货架",
	"办公椅": "办公椅",
	"文件柜": "文件柜",

	"灯饰照明":"灯饰照明",
	"吸顶灯": "吸顶灯",
	"台灯": "台灯",
	"吊灯": "吊灯",
	"壁灯": "壁灯",
	"镜前灯": "镜前灯",
	"落地灯": "落地灯",
	"氛围照明": "氛围照明",
	"杀菌灯": "杀菌灯",
	

	"母婴":"母婴",


	"奶粉":"奶粉",
	"特殊配方奶粉": "特殊配方奶粉",
	"有机奶粉": "有机奶粉",
	"1段": "1段",
	"2段": "2段",
	"3段": "3段",
	"4段": "4段",
	"羊奶粉": "羊奶粉",
	"孕妈奶粉": "孕妈奶粉",

	"尿布湿巾":"尿布湿巾",
	"拉拉裤": "拉拉裤",
	"成人尿裤": "成人尿布",
	"婴儿湿巾": "婴儿湿巾",

	"喂养工具":"喂养工具",
	"奶瓶奶嘴": "奶瓶奶嘴",
	"水壶/水杯": "水壶/水杯",
	"儿童餐具": "儿童餐具",
	"暖奶调奶": "暖奶调奶",
	"吸奶器": "吸奶器",
	"牙胶安抚": "牙胶安抚",
	"辅食料理机": "辅食料理机",
	"食物存储": "食物存储",
	"消毒锅/柜": "消毒锅/柜",

	"洗护用品":"洗护用品",
	"宝宝护肤": "宝宝护肤",
	"驱蚊防晒": "驱蚊防晒",
	"洗发沐浴": "洗发沐浴",
	"婴儿洗鼻器": "婴儿洗鼻器",
	"日常护理": "日常护理",
	"口腔清洁": "口腔清洁",
	"洗澡用具": "洗澡用具",
	"棉柔巾": "棉柔巾",
	"洗衣液/皂": "洗衣液/皂",
	"坐便器": "坐便器",
	"奶瓶清洗": "奶瓶清洗",
	"婴儿理发器": "婴儿理发器",

	"营养辅食":"营养辅食",
	"宝宝零食": "宝宝零食",
	"有机辅食": "有机辅食",
	"米粉/菜粉": "米粉/菜粉",
	"面条/粥": "面条/粥",
	"果泥/果汁": "果泥/果汁",
	"清火/开胃": "清火/开胃",
	"DHA": "DHA",
	"益生菌/初乳": "益生菌/初乳",
	"钙铁锌/维生素": "钙铁锌/维生素",


	"玩具":"玩具",


	"潮流玩具/毛绒":"潮流玩具/毛绒",
	"卡通周边": "卡通周边",
	"毛绒玩具": "毛绒玩具",
	"盲盒": "盲盒",
	"网游周边": "网游周边",
	"变形模型": "变形模型",
	"电影周边": "电影周边",
	"人偶/BJD/兵人": "人偶/BJD/兵人",
	"陀螺": "陀螺",
	"靠垫抱枕": "靠垫抱枕",
	"悠悠球/溜溜球": "悠悠球/溜溜球",
	"扭蛋": "扭蛋",

	"创意减压":"创意减压",
	"减压整蛊": "减压整蛊",
	"魔术道具": "魔术道具",
	"怀旧玩具": "怀旧玩具",
	"转转笔": "转转笔",
	"指尖陀螺": "指尖陀螺",
	"解锁玩具": "解锁玩具",

	"早教益智":"早教益智",
	"早教机/故事机": "早教机/故事机",
	"积木": "积木",
	"过家家玩具": "过家家玩具",
	"拼图": "拼图",
	"绘画工具": "绘画工具",
	"爬行垫/毯": "爬行垫/毯",
	"魔方": "魔方",
	"桌面玩具": "桌面玩具",
	"STEAM玩具": "STEAM玩具",
	"仿真动物玩具": "仿真动物玩具",
	"摇铃/床铃": "摇铃/床铃",
	"磁力片": "磁力片",
	"健身架": "健身架",
	"敲打玩具": "敲打玩具",
	"点读笔": "点读笔",

	"遥控/玩具":"遥控/玩具",
	"车模": "车模",
	"软弹枪": "软弹枪",
	"遥控车": "遥控车",
	"遥控飞机": "遥控飞机",
	"轨道车": "轨道车",
	"飞机模型": "飞机模型",
	"遥控船": "遥控船",
	"机器人": "机器人",

	"运动划水":"运动划水",
	"洗澡玩具": "洗澡玩具",
	"泡泡枪": "泡泡枪",
	"玩沙玩具": "沙滩玩具",
	"电动玩具枪": "电动玩具枪",
	"摇马": "摇马",
	"儿童滑梯": "儿童滑梯",
	"炫舞毯": "炫舞毯",

	"特色类目":"特色类目",
	"手办": "手办",


	"文具":"文具",


	"笔类书写工具":"笔类书写工具",
	"记号笔": "记号笔",
	"中性笔": "中性笔",
	"钢笔": "钢笔",
	"铅笔": "铅笔",
	"笔芯/替芯": "笔芯/替芯",
	"墨囊/墨水": "墨囊/墨水",
	"荧光笔": "荧光笔",
	"圆珠笔": "圆珠笔",
	"白板笔": "白板笔",
	"宝珠笔": "滾珠笔",
	"针管笔": "针管笔",
	"油漆笔": "油漆笔",
	"秀丽笔": "秀丽笔",

	"本册/印刷品":"本册/印刷品",
	"笔记本记事本": "笔记本记事本",
	"作业本": "作业本",
	"便利贴": "便利贴",
	"书签": "书签",
	"标签贴纸": "标签贴纸",
	"手账": "手账",
	"文稿纸草稿纸": "文稿纸草稿纸",
	"相册": "相册",
	"信封/红包": "信封/红包",
	"证书签到册": "证书签到册",
	"活页替芯": "活页替芯",
	"贺卡/明信片": "贺卡/明信片",
	"日程本计划本": "日程本计划本",
	"日历/台历": "日历/台历",
	"日记本": "日记本",
	"信纸": "信纸",
	"冰箱贴防撞贴": "冰箱贴防撞贴",
	"宣传单/海报": "宣传单/海报",
	"同学录纪念册": "同学录纪念册",
	"拍纸本": "纸质笔记本",
	"广告纸爆炸贴": "广告纸爆炸贴",
	"分页纸索引纸": "分页纸索引纸",
	"奖状": "奖状",
	"教学用本": "教学用本",
	"立体便签拼图": "3D贴纸拼图",
	"电话本通讯录": "电话簿",

	"学习用品":"学习用品",
	"练字帖": "练字帖",
	"文具盒/笔袋": "文具盒/笔袋",
	"橡皮擦": "橡皮擦",
	"修正带": "修正带",
	"卷笔刀卷比器": "卷笔刀",
	"贴纸": "贴纸",
	"补习袋": "补习袋",
	"书皮/书套": "书皮/书套",
	"坐姿修正器": "坐姿修正器",
	"阅读器": "阅读器",
	"姓名贴": "姓名贴",
	"握笔器": "握笔器",
	"自律打卡器": "自律打卡器",
	"学生计时器": "学生计时器",
	"学生书桌垫": "学生书桌垫",
	"儿童卡通印章": "儿童卡通印章",
	"修正液": "修正液",
	"笔套/笔帽": "笔套/笔帽",
	"姓名印章": "姓名印章",
	"桌面吸尘器": "桌面吸尘器",
	"答题卡/纸": "答题卡/纸",
	"挂书袋": "挂书袋",
	"红领巾": "红领巾",
	"词卡/图卡": "词卡/图卡",
	"放大镜": "放大镜",
	"拼音机口算机": "拼音机口算机",
	"学习护指绷带": "学习护指绷带",
	"少先队徽": "少先队徽",
	"压花器": "压花器",
	"修正贴": "修正贴",
	"少先队标志": "少先队标志",

	"财会用品":"财会用品",
	"凭证/单据": "凭证/单据",
	"印章/印油": "印章/印油",
	"账本/账册": "账本/账册",
	"橡皮筋": "橡皮筋",
	"号码机": "号码印字机",
	"印章盒/箱": "印章盒/箱",
	"复写纸": "复写纸",
	"支票夹": "支票夹",
	"支票打印机": "支票打印机",
	"湿手器": "湿手器",
	"财会软件": "财会软件",
	"手提金库": "便携式保险箱",

	"其他文具用品":"其他文具用品",
	"旗帜": "旗帜",
	"桌牌": "桌牌",
	"usb风扇": "usb风扇",
	"徽章": "徽章",
	"奖杯奖牌": "奖杯奖牌",
	"温湿度计": "温湿度计",
	"会议指示牌": "会议指示牌",
	"许愿瓶": "许愿瓶",

	"美术用品":"美术用品",
	"美术颜料": "美术颜料",
	"马克笔": "马克笔",
	"画纸/画本": "画纸/画本",
	"素描铅笔碳棒": "素描铅笔碳棒",
	"水彩笔": "水彩笔",
	"颜料画笔": "颜料画笔",
	"画架/画板": "画架/画板",
	"彩色铅笔": "彩色铅笔",
	"油画棒": "油画棒",
	"调色盘/纸/盒": "调色盘/纸/盒",
	"画布/画框": "画布/画框",
	"画袋/画包": "画袋/画包",
	"勾线笔": "勾线笔",
	"蜡笔": "蜡笔",
	"揉擦笔揉擦棉": "揉擦笔揉擦棉",
	"石膏雕塑": "石膏雕塑",
	"色卡": "色卡",
	"拷贝台临摹台": "拷贝台临摹台",
	"油画刀/刮刀": "油画刀/刮刀",
	"粉彩棒色粉笔": "粉彩棒色粉笔",
	"刮画纸": "刮画纸",
	"写生车/画车": "写生车/画车",
	"风干/速干器": "风干/速干器",
	"绘画油壶": "绘画油壶",
	"喷绘/取色笔": "喷绘/取色笔",
	"画杖": "画杖",

	"裁剪工具":"裁剪工具",
	"美工刀": "美工刀",
	"剪刀": "剪刀",
	"裁纸刀": "裁纸刀",
	"开箱器": "开箱器",
	"启信刀拆信刀": "开信刀",

	"文房四宝":"文房四宝",
	"宣纸/制品": "宣纸/制品",
	"毛笔": "毛笔",
	"文房配件": "文房配件",
	"墨汁/墨锭": "墨汁/墨锭",
	"文房套装": "文房套装",
	"毛笔字帖": "毛笔字帖",
	"砚台": "砚台",
	"书画毛毡": "书画毛毡",
	"镇尺/镇纸": "镇尺/镇纸",
	"水写布": "水写布",
	"石章": "石章",
	"文房印泥": "写字台墨水垫",
	"篆刻工具": "篆刻工具",

	"收纳用品":"收纳用品",
	"卡套/证件套": "卡套/证件套",
	"资料册": "资料册",
	"夹板": "夹板",
	"档案/资料盒": "档案/资料盒",
	"文件袋": "文件袋",
	"文件筐": "文件筐",
	"笔筒": "笔筒",
	"文件套": "文件套",
	"抽杆/报告夹": "抽杆/报告夹",
	"书立": "书立",
	"档案袋": "公文包",
	"科目袋": "科目袋",
	"风琴包/袋": "风琴包/袋",
	"事务包": "事务包",
	"钥匙管理": "钥匙管理",
	"意见/抽奖箱": "意见/抽奖箱",
	"书报/展示架": "书报/展示架",
	"证件框": "证件框",
	"快劳夹": "快劳夹",
	"孔装夹": "孔装夹",
	"办公纸箱": "办公纸箱",
	"桌面文件柜": "桌面文件柜",
	"活页袋": "活页袋",
	"名片盒": "名片盒",
	"名片册": "名片册",
	"边角夹": "边角夹",
	"报纸夹": "报纸夹",
	"吊挂夹": "吊挂夹",

	"胶粘用品":"胶粘用品",
	"透明胶带": "透明胶带",
	"胶水": "胶水",
	"双面胶带": "双面胶带",
	"固体胶": "固体胶",
	"纳米胶带": "纳米胶带",
	"美纹纸胶带": "美纹纸胶带",
	"布基胶带": "布基胶带",
	"点点胶": "点点胶",
	"警示胶带": "警示胶带",
	"绝缘胶带": "绝缘胶带",
	"铝箔胶带": "铝箔胶带",
	"牛皮纸": "牛皮纸",
	"封箱器": "封箱器",
	"学生白胶": "学生白胶",
	"隐形胶带": "隐形胶带",
	"胶带座": "胶带座",
	"浆糊": "浆糊",

	"绘图计算用品":"绘图计算用品",
	"计算器": "计算器",
	"直尺": "直尺",
	"测绘套装": "测绘套装",
	"圆规": "圆规",
	"皮卷尺/软尺": "皮卷尺/软尺",
	"钢卷尺": "钢卷尺",
	"三角尺": "三角尺",
	"算盘": "算盘",
	"手抄报模板": "手抄报模板",
	"绘图模板": "绘图模板",
	"比例尺": "比例尺",
	"量角器": "量角器",
	"丁字尺": "丁字尺",

	"装订用品":"装订用品",
	"票夹/长尾夹": "票夹/长尾夹",
	"磁扣/磁钉": "磁扣/磁钉",
	"订书机": "订书机",
	"打孔机": "打孔机",
	"回形针": "回形针",
	"推夹器": "推夹器",
	"别针": "别针",
	"大头针": "大头针",
	"起钉器": "起钉器",
	"图钉": "图钉",
	"三针一钉": "三针一钉",

	"教学实验用品":"教学实验用品",
	"数学教具": "数学教具",
	"地球仪": "地球仪",
	"教鞭/手指棒": "教鞭/手指棒",
	"粉笔": "粉笔",
	"黑板": "黑板",
	"板擦": "板擦",
	"教学模型": "教学模型",
	"教学刻章": "教学刻章",
	"教学标本": "教学标本",
	"显微镜": "显微镜",

	"手工制作":"手工/DIY",
	"彩泥/粘土": "彩泥/粘土",
	"折纸/DIY": "折纸/DIY",
	"咕卡套装": "咕卡套装",
	"火漆印章": "火漆印章",
	"3D打印笔": "3D打印笔",
	"剪纸/DIY": "剪纸/DIY",
	"奖励刮刮卡": "奖励刮刮卡",
	"礼品纸": "礼品纸",
	"纸雕": "纸雕",
	"衍纸": "衍纸",


	"电脑办公":"电脑办公",


	"办公用纸":"办公用纸",
	"工程纸": "工程纸",
	"其他标签纸": "其他标签纸",
	"彩激纸": "彩色激光纸",
	"其他纸类": "其他纸类",
	"传真纸": "传真纸",
	"卡纸": "卡纸",
	"铜版纸": "铜版纸",
	"条码标签纸": "条码标签纸",
	"相片纸": "相片纸",
	"复印纸": "复印纸",
	"收银纸": "收银纸",

	"外设产品":"外设产品",
	"手机U盘": "移动U盘",
	"线缆": "线缆",
	"键盘配件": "键盘配件",
	"平板电脑配件": "平板电脑配件",
	"机械键盘": "机械键盘",
	"插座": "插座",
	"笔记本配件": "笔记本配件",
	"鼠标": "鼠标",
	"鼠标垫": "鼠标垫",
	"U盘": "U盘",
	"扩展坞": "扩展坞",
	"投屏器": "投屏器",
	"移动硬盘": "移动硬盘",
	"移动固态硬盘": "移动固态硬盘",
	"硬盘盒": "硬盘盒",
	"UPS电源": "UPS电源",
	"摄像头": "摄像头",
	"手写板": "手写板",
	"网络仪表": "网络仪表",
	"机柜": "机柜",
	"电脑工具": "电脑工具",
	"电脑清洁": "电脑清洁",

	"电脑整机":"电脑整机",
	"闺蜜机": "闺蜜机",
	"轻薄本": "轻薄笔记本",
	"二合一平板": "二合一平板",
	"游戏本": "游戏本",
	"商用台式机": "商用台式机",
	"游戏台式机": "游戏台式机",
	"台式机": "台式机",
	"一体机": "一体机",
	"服务器/工作站": "服务器/工作站",

	"耗材":"耗材",
	"硒鼓": "硒鼓",
	"墨盒": "墨盒",
	"色带": "色带",
	"纸类": "纸类",
	"刻录光盘": "刻录光盘",
	"胶带": "胶带",

	"电脑组件":"电脑组件",
	"显示器": "显示器",
	"显示器配件": "显示器配件",
	"SSD固态硬盘": "SSD固态硬盘",
	"散热器": "散热器",
	"内存": "内存",
	"显卡": "显卡",
	"机箱": "底盘",
	"组装电脑": "组装电脑",
	"电源": "电源",
	"主板": "主板",
	"硬盘": "硬盘",
	"装机配件": "装机配件",
	"CPU": "CPU",
	"刻录机/光驱": "刻录机/光驱",
	"声卡/扩展卡": "声卡/扩展卡",

	"办公设备":"办公设备",
	"打印机": "打印机",
	"投影机": "投影仪",
	"投影配件": "投影配件",
	"家用打印机": "家用打印机",
	"传真设备": "传真设备",
	"验钞/点钞机": "验钞/点钞机",
	"扫描仪": "扫描仪",
	"条码扫描/采集器": "条码扫描/采集器",
	"复合机": "复合机",
	"碎纸机": "碎纸机",
	"考勤门禁": "考勤门禁",
	"收银机": "收银机",
	"激光笔": "激光笔",
	"保险柜/箱": "保险柜/箱",
	"打码机": "打码机",
	"安防监控": "安防监控",
	"会议音频视频": "会议音频视频",
	"写字板/支架": "写字板/支架",
	"办公大屏": "办公大屏",
	"会议平板": "会议平板",
	"广告机": "广告机",

	"网络产品":"网络产品",
	"路由器": "路由器",
	"网线": "网线",
	"网络配件": "网络配件",
	"交换机": "交换机",
	"5G/4G上网": "5G/4G上网",
	"网卡": "网卡",
	"网络盒子": "网络盒子",
	"网络存储": "网络存储",

	"游戏设备":"游戏设备",
	"手柄/方向盘": "手柄/方向盘",
	"游戏软件": "游戏软件",
	"游戏周边": "游戏周边",
	"游戏机": "游戏机",
	"游戏耳机": "游戏耳机",


	"数码":"数码",


	"数码趋势":"数码趋势",
	"AI学习机": "AI学习机",
	"开放式耳机": "开放式耳机",
	"智能健康手表": "智能健康手表",
	"Vlog相机": "Vlog相机",
	"AR眼镜": "AR眼镜",
	"AI手表": "AI手表",
	"AI眼镜": "AI眼镜",
	"AI相机": "AI相机",
	"AI耳机": "AI耳机",
	"AI音箱": "AI音箱",
	"AI门锁": "AI门锁",
	"AI摄像头": "AI摄像头",

	"影音娱乐":"影音娱乐",
	"直播设备": "直播设备",
	"居家艺术音箱": "居家艺术音箱",
	"HIFI音箱": "HIFI音箱",
	"降噪耳机": "降噪耳机",
	"耳夹式耳机": "耳夹式耳机",
	"运动耳机": "运动耳机",
	"眼镜耳机": "眼镜耳机",
	"颈挂式耳机": "颈挂式耳机",
	"头戴式耳机": "头戴式耳机",
	"骨传导耳机": "骨传导耳机",
	"蓝牙耳机": "蓝牙耳机",
	"乐器音箱": "乐器音箱",
	"舞台音箱": "舞台音箱",
	"拉杆/K歌": "拉杆/卡拉OK",
	"智能音箱": "智能音箱",
	"音箱/音响": "音箱/音响",
	"有线耳机": "有线耳机",
	"麦克风": "麦克风",
	"游戏耳机": "游戏耳机",
	"MP3/MP4": "MP3/MP4",
	"专业音频": "专业音响",
	"音频线": "音频线",
	"收音机": "收音机",

	"数码配件":"数码配件",
	"usb风扇": "usb风扇",
	"智能手环配件": "智能手环配件",
	"手持稳定器": "手持稳定器",
	"镜头附件": "镜头附件",
	"智能手表配件": "智能手表配件",
	"耳机配件": "耳机配件",
	"电池/充电器": "电池/充电器",
	"储存卡": "储存卡",
	"机身附件": "机身附件",
	"相机包": "相机包",
	"三脚架/云台": "三脚架/云台",
	"滤镜": "滤镜",
	"运动相机配件": "运动相机配件",
	"读卡器": "读卡器",
	"相机清洁/贴膜": "相机清洁/贴膜",
	"闪光灯/手柄": "闪光灯/手柄",

	"智能设备":"智能设备",
	"电话手表": "电话手表",
	"XR设备": "XR设备",
	"智能戒指": "智能戒指",
	"监控摄像": "监控摄像",
	"智能家具": "智能家具",
	"智能儿童手表": "智能儿童手表",
	"无人机": "无人机",
	"健康监测": "健康监测",
	"智能机器人": "智能机器人",
	"智能配饰": "智能配饰",
	"智能出行": "智能出行",
	"运动跟踪器": "运动跟踪器",

	"摄影摄像":"摄影摄像",
	"数码相框": "数码相框",
	"影棚器材": "影棚器材",
	"微单相机": "微单相机",
	"拍立得": "拍立得",
	"数码相机": "数码相机",
	"单反相机": "单反相机",
	"冲印服务": "冲印服务",
	"摄像机": "摄像机",
	"户外器材": "户外器材",

	"电子教育":"电子教育",
	"学习机": "学习机",
	"电纸书": "电子书",
	"电子词典": "电子词典",
	"录音笔": "录音笔",
	"点读机/笔": "点读机/笔",
	"复读机": "复读机",
	"翻译机": "翻译机",
	"早教益智": "早教益智",


	"运动户外":"运动户外",


	"骑行运动":"骑行运动",
	"穿戴装备": "穿戴装备",
	"山地车": "山地车",
	"城市自行车": "城市自行车",
	"折叠车": "折叠车",

	"户外装备":"户外装备",
	"旅游用品": "旅游用品",
	"户外照明": "户外照明",
	"便携桌椅床": "便携桌椅床",
	"帐篷/垫子": "帐篷/垫子",
	"冲浪潜水": "冲浪潜水",
	"野餐/烧烤": "野餐/烧烤",
	"望远镜": "望远镜",
	"户外工具": "户外工具",
	"军迷用品": "军迷用品",
	"登山攀岩": "登山攀岩",
	"救援装备": "救援装备",
	"睡袋/吊床": "睡袋/吊床",
	"户外仪表": "户外仪器",
	"极限户外": "极限户外",
	"滑雪装备": "滑雪装备",

	"垂钓用具":"垂钓用具",
	"鱼饵": "鱼饵",
	"钓鱼配件": "钓鱼配件",
	"浮漂": "鱼漂",
	"鱼线": "鱼线",
	"鱼线轮": "卷线器",
	"钓箱钓椅": "钓箱钓椅",
	"渔具包": "渔具包",
	"钓鱼灯": "钓鱼灯",
	"辅助装备": "辅助装备",
	"钓竿": "钓竿",

	"体育用品":"体育用品",
	"羽毛球拍": "羽毛球拍",
	"棋牌麻将": "棋牌麻将",
	"篮球": "篮球",
	"乒乓球拍": "乒乓球拍",
	"轮滑滑板": "轮滑滑板",
	"足球": "足球",
	"网球拍": "网球拍",
	"田径鞋": "田径鞋",
	"排球": "排球",
	"台球桌": "台球桌",
	"高尔夫球杆套杆": "高尔夫球杆套装",
	"棒球": "棒球",

	"健身训练":"健身训练",
	"哑铃": "哑铃",
	"瑜伽用品": "瑜伽用品",
	"其他器械": "其他器械",
	"武术搏击": "武术搏击",
	"跑步机": "跑步机",
	"动感单车": "动感单车",
	"甩脂机": "甩脂机",
	"综合训练器": "综合训练器",
	"椭圆机": "椭圆机",
	"仰卧板/收腹机": "仰卧板/健腹器",
	"踏步机": "踏步机",


	"箱包":"箱包",


	"功能箱包":"功能箱包",
	"旅行配件": "旅行配件",
	"护脊书包": "护脊书包",
	"行李箱": "行李箱",
	"儿童行李箱": "儿童行李箱",
	"儿童书包": "儿童书包",
	"旅行包": "旅行包",

	"潮流女包":"潮流女包",
	"单肩包": "单肩包",
	"迷你包": "迷你包",
	"手提包": "手提包",
	"女士皮带": "女士皮带",
	"帆布包": "帆布包",
	"斜挎包": "斜挎包",

	"精品男包":"精品男包",
	"男士手机包": "男士手机包",
	"男士皮带": "男士皮带",
	"单肩斜挎包": "单肩斜挎包",
	"男士双肩包": "男士双肩包",
	"男士胸包": "男士胸包",
	"商务公文包": "商务公文包",


	"钟表珠宝":"钟表珠宝",


	"腕表":"腕表",
	"国表": "国表",
	"钟表配件": "钟表配件",
	"挂钟": "挂钟",
	"闹钟": "闹钟",
	"智能手表": "智能手表",
	"儿童手表": "儿童手表",

	"饰品":"饰品",
	"手链/脚链": "手链/脚链",
	"项链": "项链",
	"耳饰": "耳饰",
	"饰品配件": "饰品配件",
	"时尚戒指": "时尚戒指",
	"胸针": "胸针",
	"发饰/发卡": "发饰/发卡",
	"水晶玛瑙": "水晶玛瑙",
	

	"奢侈品":"奢侈品",


	"男包":"男包",

	"女包":"女包",

	"饰品":"饰品",

	"配饰":"配饰",


	"机械设备":"机械设备",


	"运输工具":"运输工具",
	"三轮车": "三轮车",
	"货车": "货车",
	"拖拉机": "拖拉机",
	"飞行器": "飞行器",

	"全部":"全部",
}